import { FC, useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';

import { FormInput } from 'molecules/FormInput';
import { Box, Button, Text, Flex, Icon } from 'atoms';

import Strings from 'strings';
import { PASSWORD_REGEX } from 'settings/constants';
import useVisibleState from 'hooks/useVisibleStates';
import { useLocationQuery } from 'hooks/useLocationQuery';

import useAuth from '../context/Authentication';

const { User: UserString } = Strings;

type formValuesType = {
	password: any;
};

interface ResetPasswordProps {
	onSubmit?: (
		values?: formValuesType,
		formikHelpers?: FormikHelpers<formValuesType>
	) => void | Promise<void>;
	roleId?: string;
}

const ResetPasswordValidationSchema = yup.object().shape({
	password: yup
		.string()
		.trim()
		.required(UserString.password.required)
		.matches(PASSWORD_REGEX, UserString.password.strength),
	confirm_password: yup
		.string()
		.required(UserString.confirmpassword.required)
		.oneOf([yup.ref('password'), 'password'], UserString.confirmpassword.notMatch),
});

export const ResetPassword: FC<ResetPasswordProps> = ({ roleId }: ResetPasswordProps) => {
	const searchParams = useLocationQuery();
	const {
		actions: { outsetaResetPassword },
	} = useAuth();
	const [loading, setLoading] = useState(false);

	const { visible, show } = useVisibleState();

	function getInitValue() {
		return {
			confirm_password: '',
		};
	}

	const onSubmit = useCallback(
		(values) => {
			setLoading(true);
			outsetaResetPassword(values, (data) => {
				show();
				setLoading(false);
			});
			setTimeout(() => setLoading(false), 1000);
		},
		[outsetaResetPassword, show]
	);

	const [initialValue, setInitialValue] = useState<any>(getInitValue());

	useEffect(() => {
		setInitialValue(getInitValue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
			minWidth={{ sm: '32rem', lg: '44rem' }}
			maxWidth="90%"
		>
			<Box mb="2rem">
				<Flex justifyContent={'start'} mt="0.7rem" mb="5.6rem">
					<Icon name="logo" height="7rem" type={'text'} />
				</Flex>
			</Box>
			<Text
				fontSize="h3"
				lineHeight="h3"
				color="primary.50"
				fontFamily="Archivo-Bold"
				mt="3rem"
				mb="2.5rem"
			>
				Reset Password
			</Text>
			{visible ? (
				<></>
			) : (
				<Formik
					initialValues={initialValue}
					enableReinitialize
					onSubmit={async (formValues: any, actions) => {
						onSubmit({ ...searchParams, NewPassword: formValues?.password });
					}}
					validationSchema={ResetPasswordValidationSchema}
				>
					{() => (
						<Form>
							<Box mt="3rem">
								<FormInput
									label="Password"
									name="password"
									type="password"
									placeholder={'Password'}
									fontFamily="medium"
									maxLength={20}
								/>
								<FormInput
									label="Confirm Password"
									name="confirm_password"
									type="password"
									placeholder="Password"
									fontFamily="medium"
									maxLength={20}
								/>
								<Box mb="2rem">
									<Text>Minimum length of 8 characters.</Text>
									<Text>Include at least one uppercase letter (A-Z).</Text>
									<Text>Include at least one lowercase letter (a-z).</Text>
									<Text>Include at least one number (0-9).</Text>
									<Text>Include at least one special character (!@#$%^&*).</Text>
								</Box>
								<Button
									type="submit"
									variant="primary"
									fontSize="16px"
									my="1rem"
									height="41px"
									width="100%"
									loading={loading}
								>
									Continue
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			)}
		</Box>
	);
};
