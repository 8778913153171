import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';

import MemoCloseIcon from 'assets/icons/CloseIcon';
import { Box, Flex, Text } from 'atoms';
import { message, Tooltip, Popconfirm } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { handleRegularUpload } from 'hooks/useUploader';
import { ASSET_ENDPOINT } from 'settings/constants';
import { randomString } from 'utils/helpers';

import useAuth from 'pages/Auth/context/Authentication';

export interface EditorProps {
	id?: string;
	initialContent?: any;
	initialAttachments?: any;
	placeholder?: string;
	heightInPx?: string;
	handleReturn?: any;
	isExternalAttachment?: boolean;
	allowImageUpload?: boolean;
	onAttachmentsChanged?: (attachment: any) => void;
	onContentChange?: (content: string, plainText: string) => void;
}

const attachments = () => {
	document.getElementById('fileInput').click();
};

const handleUpload = (file: any, userId: string, maxSize: number = 2.5) => {
	return new Promise((resolve, reject) => {
		message.loading(`Uploading...`);
		let relativePath = `${userId ? `users/${userId}/attachments/` : `unauth/attachments/`}`;

		handleRegularUpload(
			file,
			{
				maxSize,
				fileName: file.name.split('.')[0],
				relativePath,
			},
			(error: any, response: any) => {
				if (error) {
					reject(error);
				} else {
					resolve({
						data: {
							link: ASSET_ENDPOINT + response.key + `?ts=${Date.now()}`,
						},
					});
				}
			}
		);
	});
};

const CustomToolbar = ({ id, isExternalAttachment, allowImageUpload }: any) => (
	<div id={id}>
		<button className="ql-bold" />
		<button className="ql-italic" />
		<button className="ql-underline" />
		<button className="ql-strike" />
		<button className="ql-list" value="ordered" />
		<button className="ql-list" value="bullet" />
		<select className="ql-color" />
		<select className="ql-background" />
		{allowImageUpload && <button className="ql-image" />}
		{isExternalAttachment && (
			<button className="ql-attachments">
				<PaperClipOutlined />
			</button>
		)}
	</div>
);

const Editor = (props: EditorProps) => {
	const {
		id,
		heightInPx,
		placeholder,
		handleReturn,
		initialContent,
		onContentChange,
		allowImageUpload,
		isExternalAttachment,
		onAttachmentsChanged,
	} = props;

	const {
		state: { user },
	} = useAuth();

	const [editorHtml, setEditorHtml] = useState('');
	const [attachment, addAttachments]: any = useState([]);

	useEffect(() => {
		setEditorHtml(initialContent.value || initialContent);
		addAttachments([]);
	}, [initialContent]);

	useEffect(() => {
		onAttachmentsChanged(attachment);
	}, [attachment]);

	const handleChange = (content: any, delta: any, source: any, editor: any) => {
		setEditorHtml(content);
		const text = editor.getText(content);
		onContentChange(content, text);
	};
	const handleKeyPress = (event: any) => {
		if (
			event.key === 'Enter' &&
			!(event.altKey || event.ctrlKey || event.shiftKey) &&
			handleReturn
		) {
			event.preventDefault();
			handleReturn();
			setEditorHtml('');
			addAttachments([]);
		}
	};
	const handleFileUpload = (event: any) => {
		const file = event.target.files[0];
		if (file) {
			handleUpload(file, user?._id)
				.then((res: any) => {
					addAttachments([
						...attachment,
						{
							name: file.name,
							url: res.data.link,
							id: randomString(15),
						},
					]);
				})
				.catch((e) => {});
		}
	};
	const onRemoveFileAttachment = (item: any) => {
		const filteredAttachment = attachment.filter((x: any) => x.id !== item.id);
		addAttachments([...filteredAttachment]);
	};

	const modules = {
		toolbar: {
			container: `#${id}`,
			handlers: {
				attachments: attachments,
			},
		},
	};

	const formats = [
		'bold',
		'italic',
		'underline',
		'strike',
		'list',
		'bullet',
		'color',
		'background',
		'link',
		'image',
	];
	return (
		<div className="text-editor">
			{}
			<ReactQuill
				value={editorHtml}
				onChange={handleChange}
				placeholder={placeholder}
				modules={modules}
				formats={formats}
				onKeyDown={handleKeyPress}
				style={{
					height: `${heightInPx}`,
				}}
			/>
			{attachment.length > 0 && (
				<Flex padding="10px" width="100%" flexWrap="wrap">
					{attachment.map((x: any) => (
						<Flex
							background="#f5f5f5"
							border="1px solid transparent"
							width="200px"
							justifyContent="space-between"
							alignItems="center"
							cursor="pointer"
							p="5px 10px"
							mb="10px"
							key={x.id}
						>
							<Tooltip title={x.name}>
								<Text
									as="span"
									width="150px"
									whiteSpace="nowrap"
									overflow="hidden"
									textOverflow="ellipsis"
									fontFamily="semibold"
									fontSize="12px"
								>
									{x.name}
								</Text>
							</Tooltip>
							<Popconfirm
								title="Are you sure to remove?"
								onConfirm={() => {
									onRemoveFileAttachment(x);
								}}
								okText="Yes"
								cancelText="No"
							>
								<MemoCloseIcon stroke="black" cursor="pointer" />
							</Popconfirm>
						</Flex>
					))}
				</Flex>
			)}
			<CustomToolbar
				id={id}
				isExternalAttachment={isExternalAttachment}
				allowImageUpload={allowImageUpload}
			/>
			<input id="fileInput" type="file" className="hidden" onChange={handleFileUpload} />
		</div>
	);
};

export const QuillEditor = ({
	id = 'toolbar',
	initialContent = '',
	initialAttachments = [],
	placeholder = '',
	handleReturn,
	heightInPx = '150px',
	allowImageUpload = false,
	isExternalAttachment = false,
	onAttachmentsChanged = (files: any) => {},
	onContentChange = (e: string) => {},
}: EditorProps) => (
	<div className="quillEditor">
		<Editor
			id={id}
			heightInPx={heightInPx}
			placeholder={placeholder}
			initialContent={initialContent}
			handleReturn={handleReturn}
			onContentChange={onContentChange}
			allowImageUpload={allowImageUpload}
			isExternalAttachment={isExternalAttachment}
			onAttachmentsChanged={onAttachmentsChanged}
		/>
	</div>
);
