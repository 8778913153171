import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ResetPassword } from '../ResetPassword';
import './login.css';

import { useLocationQuery } from 'hooks/useLocationQuery';

import { Box, Text, Icon, Flex } from 'atoms';

export type LoginProps = { userType?: number };

export const Login: FC<LoginProps> = ({ userType }) => {
	const searchParams = useLocationQuery();
	return (
		<Box
			mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
			minWidth={{ sm: '32rem', lg: '44rem' }}
			maxWidth="90%"
		>
			<Box mb="2.5rem">
				<Flex justifyContent={'start'} mt="0.7rem" mb="5.6rem">
					<Icon name="logo" height="7rem" type={'text'} />
				</Flex>

				<Text
					fontSize="h3"
					lineHeight="h3"
					color="primary.50"
					fontFamily="Archivo-Bold"
					mt="3rem"
					mb="2.5rem"
				>
					Welcome back
				</Text>
			</Box>

			{searchParams?.resetToken ? (
				<>
					<ResetPassword />
				</>
			) : (
				<>
					<div id="login-embed"></div>
					<Flex justifyContent={'center'}>
						<Link to="/forgot-password" className="o--text-sm">
							<Text color="secondary.50" fontFamily={'semibold'} fontSize={'1.6rem'}>
								Forgot password
							</Text>
						</Link>
					</Flex>
				</>
			)}
		</Box>
	);
};
