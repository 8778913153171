import { useReducer } from 'react';
import { toast as message } from 'react-toastify';

import { api } from 'utils/api/api';
import { buildQuery } from 'utils/buildQuery';
import { createAction, createContainer, createReducer } from 'utils/context';
import * as APIRoutes from './APIRoutes';

export type UsersState = {
	list: any;
	poolList: any;
	users: any;
	poolCoaches: any;
	loading: boolean;
	currentUser: any;
	dropdown: any;
	count: any;
	currentPool: any;
	currentPoolUsers: any;
	optionList: any;
	adminList: {
		columns: any[];
		meta: any;
		users: any[];
	};
	coachesList: {
		meta: any;
		users: any[];
	};
	currentAdminUser: any;
};

const initialState: UsersState = {
	list: {
		users: [],
		meta: {},
	},
	users: [],
	currentUser: null,
	loading: true,

	poolCoaches: [],
	dropdown: [],
	count: {
		coachesOnLanguages: 0,
		coachesOnLocation: 0,
		coachesOnTimezone: 0,
		coachesOnAccrediation: 0,
		coachesOnSeniority: 0,
		coachesOnSchool: 0,
		coachesOnIndustry: 0,
		coachesOnExperienceInYears: 0,
		coachesOnFeedbackRating: 0,
	},
	poolList: {
		pools: [],
		meta: {},
	},
	currentPool: null,
	currentPoolUsers: [],
	optionList: {
		seniorityOptions: [],
		industryOptions: [],
		languageOptions: [],
	},
	adminList: {
		columns: [],
		meta: {},
		users: [],
	},
	coachesList: {
		meta: {},
		users: [],
	},
	currentAdminUser: null,
};

const actions = {
	fetchUsers: createAction('USERS_LIST'),
	createOne: createAction('USER_CREATE'),
	getOne: createAction('GET_ONE_USER'),
	fetchCoachUsers: createAction('COACH_USERS_LIST'),

	getOptions: createAction('GET_OPTIONS_LIST'),
	getPoolCoaches: createAction('GET_POOL_USERS'),
	getPoolUserCount: createAction('GET_POOL_USERS_COUNT'),
	getPools: createAction('GET_POOL_LIST'),
	getOnePool: createAction('GET_ONE_POOL'),
	getOnePoolUsers: createAction('GET_ONE_POOL_USERS'),
	updatePoolOfCoaches: createAction('UPDATE_ONE_POOL'),

	getAdminUsers: createAction('ADMIN_USERS_LIST'),
	getAdminUserById: createAction('GET_ONE_ADMIN_USERS'),
	updateAdminUser: createAction('UPDATE_ADMIN_USER'),

	setLoading: createAction('SET_LOADING'),
	resetUsers: createAction('RESET_USERS'),
};

const userReducer = createReducer<UsersState>({
	[`${actions.resetUsers}`]: (state) => ({
		list: {
			users: [],
			meta: {},
		},
		users: [],
		currentUser: null,
		loading: true,

		poolCoaches: [],
		dropdown: [],
		count: {
			coachesOnLanguages: 0,
			coachesOnLocation: 0,
			coachesOnTimezone: 0,
			coachesOnAccrediation: 0,
			coachesOnSeniority: 0,
			coachesOnSchool: 0,
			coachesOnIndustry: 0,
			coachesOnExperienceInYears: 0,
			coachesOnFeedbackRating: 0,
		},
		poolList: {
			pools: [],
			meta: {},
		},
		currentPool: null,
		currentPoolUsers: [],
		optionList: {
			seniorityOptions: [],
			industryOptions: [],
			languageOptions: [],
		},
		adminList: {
			columns: [],
			meta: {},
			users: [],
		},
		coachesList: {
			meta: {},
			users: [],
		},
		currentAdminUser: null,
	}),
	[`${actions.fetchUsers}`]: (state, { payload }) => ({
		...state,
		list: {
			users: payload?.users || [],
			meta: payload?.meta,
		},
		loading: false,
	}),
	[`${actions.fetchCoachUsers}`]: (state, { payload }) => ({
		...state,
		coachesList: {
			users: payload?.users || payload || [],
			meta: payload?.meta,
		},
	}),
	[`${actions.getOne}`]: (state, { payload }) => ({
		...state,
		currentUser: payload?.user,
		loading: false,
	}),
	[`${actions.getOptions}`]: (state, { payload }) => ({
		...state,
		optionList: payload,
	}),

	[`${actions.getPoolCoaches}`]: (state, { payload }) => ({
		...state,
		poolCoaches: payload.users,
	}),
	[`${actions.getPoolUserCount}`]: (state, { payload }) => ({
		...state,
		count: payload,
	}),
	[`${actions.getPools}`]: (state, { payload }) => ({
		...state,
		poolList: {
			pools: payload?.coachPools || [],
			meta: payload?.meta,
		},
	}),
	[`${actions.getOnePool}`]: (state, { payload }) => ({
		...state,
		currentPool: payload?.coachPool,
	}),
	[`${actions.getOnePoolUsers}`]: (state, { payload }) => ({
		...state,
		currentPoolUsers: payload?.data,
	}),
	[`${actions.updatePoolOfCoaches}`]: (state, { payload }) => ({
		...state,
		currentPool: payload?.data,
	}),

	[`${actions.getAdminUsers}`]: (state, { payload }) => ({
		...state,
		adminList: payload,
	}),
	[`${actions.getAdminUserById}`]: (state, { payload }) => ({
		...state,
		currentAdminUser: payload.user.user,
		loading: false,
	}),
	[`${actions.updateAdminUser}`]: (state, { payload }) => ({
		...state,
		currentAdminUser: payload.user.user || payload.user,
	}),
	[`${actions.setLoading}`]: (state, { payload }) => ({
		...state,
		loading: payload,
	}),
});

export const {
	useContext: useUsers,
	Context: UserContext,
	Provider: UserProvider,
} = createContainer(() => {
	const [state, dispatch] = useReducer(userReducer, {
		...initialState,
	});

	const fetchUsers = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams?.all && !queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			const { data } = await api(`${APIRoutes.USERS}?${buildQuery(queryParams)}`, {
				method: 'get',
			});

			dispatch(actions.fetchUsers(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const fetchCoaches = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams?.all && !queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			const { data } = await api(`${APIRoutes.COACH_USERS}?${buildQuery(queryParams)}`, {
				method: 'get',
			});

			dispatch(actions.fetchCoachUsers(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const createOne = async (values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.USERS, {
				method: 'post',
				data: { ...values },
			});
			message.success('User Created successfully');
			dispatch(actions.createOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const getOne = async (id: any, callback?: (data: any) => void) => {
		try {
			dispatch(actions.setLoading(true));
			const { data } = await api(`${APIRoutes.USERS}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const updateOne = async (id: string, values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.USERS}/${id}`, {
				method: 'put',
				data: { ...values },
			});
			message.success('Updated successfully');
			dispatch(actions.createOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const deleteUser = async (id: string, type: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.USERS}/${id}?flag=${type}`, {
				method: 'delete',
			});
			message.success('User deleted successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	// Pool
	const getOptions = async (callback?: (data: any) => void) => {
		try {
			const queryParams: any = {
				all: true,
			};
			const tagResponse = await api(`${APIRoutes.TAGS}?${buildQuery(queryParams)}`, {
				method: 'get',
			});
			queryParams.sort = 'label|ASC';

			const languageResponse = await api(
				`${APIRoutes.LANGUAGES}?${buildQuery(queryParams)}`,
				{
					method: 'get',
				}
			);
			const eng = languageResponse?.data?.languages.find(
				(language: any) => language?.label == 'English'
			);
			const languageOptions = [
				eng,
				...languageResponse?.data?.languages?.filter(
					(language: any) => language?.label !== 'English'
				),
			];
			const seniorityOptions = tagResponse?.data?.tags?.filter(
				(tag: any) => tag?.tagType?.type == 2
			);
			const industryOptions = tagResponse?.data?.tags?.filter(
				(tag: any) => tag?.tagType?.type == 3
			);
			const payload = { languageOptions, seniorityOptions, industryOptions };
			dispatch(actions.getOptions(payload));
			if (typeof callback === 'function') {
				callback(payload);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const getPoolCoaches = async (filters: any, callback?: (data: any) => void) => {
		const queryParams = {
			page: 1,
			limit: 1000,
		};
		try {
			const { data } = await api(`${APIRoutes.USERS}/filters?${buildQuery(queryParams)}`, {
				method: 'post',
				data: { ...filters },
			});
			dispatch(actions.getPoolCoaches(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const getPoolUserCount = async (payload: any, callback?: (data: any) => void) => {
		try {
			let { data } = await api(`${APIRoutes.USERS}/counts`, {
				method: 'post',
				data: { ...payload },
			});

			dispatch(actions.getPoolUserCount(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const createPoolOfCoaches = async (payload: any, callback?: (data: any) => void) => {
		try {
			await api(APIRoutes.POOLS, {
				method: 'post',
				data: { ...payload },
			});
			message.success('Pool of coaches created successfully');
		} catch (error) {
			message.error(error.message);
		}
	};
	const getPools = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					statuses: [1],
					page: 1,
					limit: 10,
				};
			} else if (!queryParams?.all && !queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
					statuses: [1],
				};
			}
			const { data } = await api(`${APIRoutes.POOLS}?${buildQuery(queryParams)}`, {
				method: 'get',
			});
			dispatch(actions.getPools(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const getOnePool = async (id: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.POOLS}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getOnePool(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const getOnePoolUsers = async (id: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.POOLS}/${id}/users`, {
				method: 'get',
			});
			dispatch(actions.getOnePoolUsers(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const updatePoolOfCoaches = async (id: any, payload: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.POOLS}/${id}`, {
				method: 'put',
				data: { ...payload },
			});
			dispatch(actions.updatePoolOfCoaches(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const deletePool = async (id: string, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.POOLS}/${id}`, {
				method: 'delete',
			});
			message.success('Pool deactivated successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const resetPoolOfCoaches = () => {
		dispatch(actions.updatePoolOfCoaches({ users: [] }));
	};
	const resetCoachesCount = () => {
		dispatch(
			actions.getPoolUserCount({
				coachesOnLanguages: 0,
				coachesOnLocation: 0,
				coachesOnTimezone: 0,
				coachesOnAccrediation: 0,
				coachesOnSeniority: 0,
				coachesOnSchool: 0,
				coachesOnIndustry: 0,
				coachesOnExperienceInYears: 0,
				coachesOnFeedbackRating: 0,
			})
		);
	};

	//Admin Users
	const getAdminUsers = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams?.all && !queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}
			if (!queryParams?.sort) {
				queryParams = {
					...queryParams,
					sort: 'createdAt|DESC',
				};
			}

			const { data } = await api(`${APIRoutes.ADMINUSERS}?${buildQuery(queryParams)}`, {
				method: 'get',
			});

			dispatch(actions.getAdminUsers(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const getAdminUserById = async (
		listType: string,
		id: string,
		callback?: (data: any) => void
	) => {
		try {
			dispatch(actions.setLoading(true));
			const { data } = await api(`${APIRoutes.ADMINUSERS}/${listType}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getAdminUserById(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const updateAdminUser = async (
		listType: string,
		id: string,
		payload: any,
		callback?: (data: any) => void
	) => {
		try {
			const { data } = await api(`${APIRoutes.ADMINUSERS}/${listType}/${id}`, {
				method: 'put',
				data: { ...payload },
			});
			dispatch(actions.updateAdminUser(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const updateAdminUserFreeCredit = async (
		id: string,
		payload: any,
		listType: string,
		callback?: (data: any) => void
	) => {
		try {
			const { data } = await api(`${APIRoutes.ADMINUSERS}/credits/${id}`, {
				method: 'put',
				data: { ...payload },
			});
			getAdminUserById(listType, id);
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const deactivateAdminUser = async (
		listType: string,
		id: string,
		callback?: (data: any) => void
	) => {
		try {
			const { data } = await api(`${APIRoutes.ADMINUSERS}/deactivate/${listType}/${id}`, {
				method: 'put',
			});
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const resetUsers = () => {
		dispatch(actions.resetUsers());
	};
	return {
		state,
		actions: {
			fetchUsers,
			createOne,
			updateOne,
			getOne,
			deleteUser,
			getOptions,
			getPoolCoaches,
			getPoolUserCount,
			createPoolOfCoaches,
			getPools,
			getOnePool,
			getOnePoolUsers,
			updatePoolOfCoaches,
			deletePool,
			resetPoolOfCoaches,
			resetCoachesCount,
			fetchCoaches,
			getAdminUsers,
			getAdminUserById,
			updateAdminUser,
			updateAdminUserFreeCredit,
			deactivateAdminUser,
			resetUsers,
		},
	};
});

export default useUsers;
