import {
	DatePicker as AntDatePicker,
	DatePickerProps as AntDatePickerProps,
	TimeRangePickerProps,
} from 'antd';
import 'antd/dist/antd.min.css';
import { Box } from 'atoms/Box';
import { Merge } from 'typings/utils';

const { RangePicker } = AntDatePicker;
export interface DateProps extends TimeRangePickerProps {
	onChangeDate?: (date: any, dateStrings: any) => void;
	isRangePicker?: boolean;
	style?: object;
	disabled?: boolean;
}

export type DatePickerProps = Merge<DateProps, AntDatePickerProps>;

export const DatePicker = ({
	onChangeDate,
	isRangePicker = false,
	style,
	allowClear,
	disabledDate,
	placeholder,
	disabled,
	...props
}: DatePickerProps) => {
	const Range = () => {
		return (
			<RangePicker
				className="mcp-date-picker-style"
				format={'MMM DD YYYY'}
				onChange={(date: any, dateStrings: any) =>
					onChangeDate && onChangeDate(date, dateStrings)
				}
				style={style}
				disabledDate={disabledDate && disabledDate}
				value={props.value as any}
				allowClear={allowClear}
			/>
		);
	};

	const DateP = () => {
		return (
			<AntDatePicker
				format={`${props?.format || 'MMM DD YYYY'}`}
				className={`mcp-date-picker-style ${props?.className || ''}`}
				onChange={(date: any, dateStrings: any) =>
					onChangeDate && onChangeDate(date, dateStrings)
				}
				style={style}
				allowClear={allowClear}
				// open={open}
				disabledDate={disabledDate}
				disabled={disabled}
				placeholder={placeholder || 'Select date'}
				//value={props.value as any}
				//value={props.value}
				defaultValue={props.defaultValue as any}
			/>
		);
	};
	return <Box>{isRangePicker ? Range() : DateP()}</Box>;
};
