import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Modal } from 'antd';

import { Box, Text, Button, Grid, Image, Flex, Icon } from 'atoms';
import CompleteProfileForm from 'templates/CompleteProfileForm';
import { useLocationQuery } from 'hooks/useLocationQuery';
import SubscriptionGroup from 'assets/images/SubscriptionGroup.png';
import { DEFAULT_FREE_CREDIT } from 'typings/enums/users';
import useAuth from '../context/Authentication';
import { SchoolProvider } from 'pages/Schools/context/schools';
import { CohortProvider } from 'pages/Cohorts/context/cohorts';

export type CompleteProfileProps = {};

export const CompleteProfile: FC<CompleteProfileProps> = () => {
	const navigate = useNavigate();
	const searchParams: any = useLocationQuery();
	const url: any = searchParams.continue;
	const [showSubscriptionModal, setShowSubscriptonModal] = useState<boolean>(false);

	const {
		state: { user, currentPortal },
		actions: { updateProfile, switchProtal, invitationTokenVerify },
	} = useAuth();

	useEffect(() => {
		// if (currentPortal === 'coach' && user?.meta?.basicProfileCompleted) {
		// 	navigate(`/${currentPortal}/home`);
		// }
		if (
			user?.meta?.basicProfileCompleted &&
			((currentPortal === 'coach' &&
				sessionStorage.getItem('subscriptionModalCompleted') !== 'true') ||
				currentPortal === 'coachee')
		) {
			navigate(`/${currentPortal}/home`);
		}
	}, [user, currentPortal, navigate]);

	const completeProfile = useCallback(
		(values) => {
			let newData;
			if (currentPortal === 'coach') {
				newData = {
					...values,
					organization: searchParams?.organization,
					organizationVertical: searchParams?.organizationVertical,
					codeVerifiedForOrganization:
						searchParams?.organization !== null &&
						searchParams?.organizationVertical !== null,
					meta: { basicProfileCompleted: true, marketingAgreed: values.marketingAgreed },
					userType: currentPortal,
				};
			} else if (currentPortal === 'coachee') {
				newData = {
					...values,
					organization: searchParams?.organization,
					organizationVertical: searchParams?.organizationVertical,
					codeVerifiedForOrganization:
						searchParams?.organization !== null &&
						searchParams?.organizationVertical !== null,
					meta: { basicProfileCompleted: true, codeVerifiedForOrganization: true },
					userType: currentPortal,
				};
			}

			updateProfile({ ...newData, sendRegisterNotification: true }, (data: any) => {
				const coach = currentPortal === 'coach';
				// Check the highest profile users higher profile is coach.
				if (coach) {
					switchProtal('coach');
					if (values?.school) navigate('/coach/code-verify');
					else setShowSubscriptonModal(true);
				} else {
					if (searchParams?.connection) {
						invitationTokenVerify(
							{ invitationToken: searchParams?.invitationToken, status: 'active' },
							searchParams?.connection,
							() => {
								switchProtal('coachee');
								navigate('/coachee/home');
							}
						);
					} else {
						switchProtal('coachee');
						navigate('/coachee/home');
					}
				}
			});
		}, // eslint-disable-next-line
		[updateProfile, navigate, searchParams?.continue, switchProtal, url]
	);

	return (
		<Box>
			<Flex mb="2rem">
				{/* <Text
					fontSize="h4"
					lineHeight="h4"
					color="secondary.50"
					fontFamily="Archivo-Bold"
					my="0.7rem"
					mb="5rem"
				>
					My Coaching Place
				</Text> */}

				<Icon name="logo" height="7rem" type={'text'} />
			</Flex>
			<Text fontSize="23px" color="primary.50" mt="3rem" fontFamily="Archivo-Bold" mb="2rem">
				Complete your profile
			</Text>
			<Text fontSize="1.6rem">
				We just need a few more details to get your account setup.
			</Text>
			<SchoolProvider>
				<CohortProvider>
					<CompleteProfileForm
						onSubmit={completeProfile}
						userType={currentPortal}
						defaultValue={searchParams}
					/>
					<Modal
						open={showSubscriptionModal}
						onCancel={() => {
							setShowSubscriptonModal(false);
						}}
						footer={false}
						width={650}
					>
						<Box p={'12px'}>
							<Flex justifyContent={'center'}>
								<Image
									src={SubscriptionGroup}
									alt="Subscription group"
									width="308px"
								/>
							</Flex>
							<Text fontFamily="Archivo-Bold" fontSize="h4" my="1rem">
								Free Credits - Our gift to you
							</Text>
							<Text fontSize={'p1'} mb="1.5rem">
								As a special welcome gift, we are giving you{' '}
								<Text as="span" fontFamily={'bold'} color="secondary.50">
									{DEFAULT_FREE_CREDIT} session credits
								</Text>{' '}
								so you can use My Coaching Place with your own clients and really
								get to know the features and benefits. Here's how the session
								credits work:
							</Text>
							<Grid
								border="1px solid #D1D5DB"
								p="2rem"
								gridTemplateColumns={{ xs: '100%', sm: ' 1fr 1fr' }}
							>
								<Box>
									<Text fontFamily={'semibold'}>Feature</Text>
									<Text my="1.6rem">Onboarding a client +1 session *</Text>
									<Text>Scheduling a session *</Text>
								</Box>
								<Box>
									<Text fontFamily={'semibold'}>Session Credits Needed</Text>
									<Text my="1.6rem">1 session credit</Text>
									<Text>1 session credit</Text>
								</Box>
							</Grid>

							<Text fontSize={'p1'} my="1.5rem">
								*Session summary and feedback, messaging, file sharing, assigning
								templates, session logs, reflections, and agreement signing are
								included.
							</Text>
							<Button
								onClick={() => {
									sessionStorage.removeItem('subscriptionModalCompleted');
									//navigate('/coach/home');
								}}
							>
								Continue
							</Button>
						</Box>
					</Modal>
				</CohortProvider>
			</SchoolProvider>
		</Box>
	);
};
