import { Tooltip } from 'antd';
import { Box, Flex, Grid, Icon, IconProps, Text } from 'atoms';
import { StyledNavLink } from 'atoms/StyledNavLink';
import useAuth from 'pages/Auth/context/Authentication';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLinkProps, Link } from 'react-router-dom';
//import { UserType } from 'typings/enums/users';

type TabType = {
	url: string;
	iconName: IconProps['name'];
	tooltip: string;
	count?: number;
};

type MenuBoxProps = {
	navigateTo: NavLinkProps['to'];
	iconName: IconProps['name'];
	onClick?: (type: string) => void;
	isselected?: boolean;
	toolTip?: string;
	name?: string;
	setCollapsed?: any;
	count?: number;
};

const MenuBox: FC<MenuBoxProps> = ({
	navigateTo,
	iconName,
	onClick,
	isselected,
	toolTip,
	count,
	setCollapsed,
}: any) => {
	return (
		<Tooltip
			placement="right"
			title={
				<Text color="white.0" fontSize="12px" fontFamily="regular">
					{toolTip}
				</Text>
			}
		>
			<StyledNavLink
				to={navigateTo}
				onClick={() => {
					setCollapsed(false);
					onClick(iconName);
				}}
				className="menulink"
			>
				<Flex>
					<Icon name={iconName} width="2rem" height="2rem" isselected={isselected} />{' '}
					<Text as="span" pl="12px">
						{toolTip}
					</Text>
					{!!count && (
						<Box
							ml="8px"
							px="8px"
							bg="#ffffff"
							minWidth="24px"
							height="24px"
							borderRadius="12px"
							color="secondary.100"
							display="flex"
							justifyContent="center"
							alignItems="center"
							fontWeight="bold"
							fontSize="14px"
						>
							{count > 10 ? '10+' : count}
						</Box>
					)}
				</Flex>
			</StyledNavLink>
		</Tooltip>
	);
};

interface SidebarProps {
	type?: string;
	onClick?: (type: string) => void;
	setCollapsed?: any;
	collapsed?: boolean;
}

const returnTabs = (userType: string, user: any, notifications: any): Array<TabType> => {
	if (user?.role?.name === 'admin' || user?.roles?.[0]?.name?.toLowerCase() === 'admin') {
		return [
			{ url: '/admin/home', iconName: 'dashboard', tooltip: 'Dashboard' },
			{
				url: '/admin/schools',
				iconName: 'school',
				tooltip: 'Training Providers',
			},
			{
				url: '/admin/cohorts',
				iconName: 'cohort',
				tooltip: 'Cohorts',
			},
			{
				url: '/admin/tags',
				iconName: 'tag',
				tooltip: 'Tags',
			},
			{
				url: '/admin/users',
				iconName: 'users',
				tooltip: 'Users',
			},
			{
				url: '/admin/organizations',
				iconName: 'organization',
				tooltip: 'Organizations',
			},
			// {
			// 	url: '/admin/messages',
			// 	iconName: 'messages',
			// 	tooltip: 'Messages',
			// },
			{
				url: '/admin/resources',
				iconName: 'resources',
				tooltip: 'Resources',
			},
			{
				url: '/admin/connections',
				iconName: 'users',
				tooltip: 'Connections',
			},
			{
				url: '/admin/sessions',
				iconName: 'sessions',
				tooltip: 'Sessions',
			},
			{
				url: '/admin/coupons',
				iconName: 'coupons',
				tooltip: 'Discount code',
			},
		];
	} else if (
		user?.role?.name === 'coachee' ||
		user?.roles?.[0]?.name?.toLowerCase() === 'coachee'
	) {
		return [
			{ url: '/coachee/home', iconName: 'dashboard', tooltip: 'Dashboard' },
			{
				url: '/coachee/sessions',
				iconName: 'sessions',
				tooltip: 'Sessions',
				count: notifications?.sessionCount,
			},
			{
				url: '/coachee/messages',
				iconName: 'messages',
				tooltip: 'Messages',
				count: notifications?.messageCount,
			},
			// {
			// 	url: '/coachee/schedules',
			// 	iconName: 'schedules',
			// 	tooltip: 'Schedules',
			// },
			{
				url: '/coachee/tasks',
				iconName: 'tasks',
				tooltip: 'Tasks',
				count: notifications?.taskCount,
			},
			{
				url: '/coachee/connections',
				iconName: 'users',
				tooltip: 'Connections',
				count: notifications?.connectionCount,
			},
		];
	} else if (user?.role?.name === 'coach' || user?.roles?.[0]?.name?.toLowerCase() === 'coach') {
		let coachMenus: any = [
			{ url: '/coach/home', iconName: 'dashboard', tooltip: 'Dashboard' },
			{
				url: '/coach/sessions',
				iconName: 'sessions',
				tooltip: 'Sessions',
			},
			{
				url: '/coach/messages',
				iconName: 'messages',
				tooltip: 'Messages',
				count: notifications?.messageCount,
			},
			{
				url: '/coach/connections',
				iconName: 'users',
				tooltip: 'Connections',
				count: notifications?.connectionCount,
			},
			{
				url: '/coach/tasks/assigned-tasks',
				iconName: 'tasks',
				tooltip: 'Tasks',
			},
			{
				url: '/coach/resources',
				iconName: 'resources',
				tooltip: 'Resources',
			},
			{
				url: '/coach/reports',
				iconName: 'reports',
				tooltip: 'Session Logs',
			},
		];

		return coachMenus;
	}
	return [];
};

export const Sidebar: React.FC<SidebarProps> = ({
	type = 'coach',
	onClick = (type: string) => {},
	setCollapsed,
	collapsed,
}: SidebarProps) => {
	const [currentSelected, setSelected] = useState('');
	const location = useLocation();
	const {
		state: { user, currentPortal, notifications },
		actions: { updateNotificationCount },
	} = useAuth();

	useEffect(() => {
		if (currentSelected) {
			return () => {
				if (currentSelected == 'sessions') {
					updateNotificationCount(0, 'sessionCount');
				} else if (currentSelected == 'messages') {
					updateNotificationCount(0, 'messageCount');
				} else if (currentSelected == 'connections') {
					updateNotificationCount(0, 'connectionCount');
				} else if (currentSelected == 'tasks') {
					updateNotificationCount(0, 'taskCount');
				}
			};
		}
	}, [currentSelected]);

	useEffect(() => {
		const module = location.pathname.split('/')[2];
		if (module) {
			setSelected(module);
		}
	}, [location]);

	const tabs: Array<TabType> = returnTabs(type, user, notifications);

	const SecondTabs: Array<TabType> = [
		{
			url: '/support',
			iconName: 'support',
			tooltip: 'Support',
		},
		{
			url: '/faq',
			iconName: 'faq',
			tooltip: 'FAQs',
		},
	];

	return (
		<Box
			minWidth={{
				_: '224px',
				//xl: '300px'
			}}
			height="100%"
			boxShadow="0px 0px 15px rgba(196, 196, 196, 0.3)"
			bg="secondary.100"
		>
			<Box
				position="sticky"
				top="0"
				display={'flex'}
				alignContent="space-between"
				flexWrap="wrap"
				height="100%"
				overflowY={{ xs: 'scroll', md: 'unset' }}
			>
				<Grid py="2.3rem" px="8px" gridGap="2px">
					<Link to={`/${currentPortal}/home`}>
						{/* <Text
							fontFamily="Archivo-Bold"
							fontSize="17px"
							lineHeight="110%"
							color="white.0"
							px="24px"
							mb="3.2rem"
							mt="1.1rem"
						>
							My Coaching Place
						</Text> */}

						<Flex justifyContent={'start'} mt="0.7rem" mb="1rem">
							<Icon name="logo" height="7rem" type={'white'} />
						</Flex>
					</Link>
					{tabs.map((tab) => {
						return (
							<MenuBox
								toolTip={tab.tooltip}
								navigateTo={tab.url}
								key={tab.url}
								iconName={tab.iconName}
								onClick={onClick}
								isselected={tab.iconName === currentSelected}
								setCollapsed={setCollapsed}
								count={tab?.count}
							/>
						);
					})}
				</Grid>

				<Grid px="8px" gridGap="2px" position="relative" width="100%" bottom="0">
					{SecondTabs?.map((tab) => {
						return (
							<MenuBox
								toolTip={tab.tooltip}
								navigateTo={tab.url}
								key={tab.url}
								iconName={tab?.iconName}
								onClick={onClick}
								isselected={tab.iconName === currentSelected}
								name={tab.tooltip}
								setCollapsed={setCollapsed}
							/>
						);
					})}
				</Grid>
			</Box>
		</Box>
	);
};
