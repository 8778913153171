import { FC } from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';

import { Box, Button, Text, Flex } from 'atoms';
import { FormInput } from 'molecules/FormInput';

import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';

export const CodeVerifyValidationSchema = () => {
	return yup.object().shape({
		code: yup
			.string()
			.required('Please enter code')
			.min(5, 'Code should have minimum 5 characters')
			.max(10, 'Code should have maximum 10 characters')
			.matches(/^[a-zA-Z0-9]*$/, {
				message: 'Alphanumeric characters only allowed',
			}),
	});
};

type CompleteProfileFormValuesType = {
	code: string;
	skip?: boolean;
};

export type CompleteProfileFormProps = {
	onSubmit?: (
		values?: CompleteProfileFormValuesType,
		formikHelpers?: FormikHelpers<CompleteProfileFormValuesType>
	) => void | Promise<void>;
	userType: string;
	isAboutPage?: boolean;
	onCancel?: any;
};
// Used for school verify function in when coach signup & profile about page update
export const CodeVerifyForm: FC<CompleteProfileFormProps> = ({
	onSubmit,
	userType,
	isAboutPage,
	onCancel,
}) => {
	const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);

	return (
		<Formik
			initialValues={{
				code: '',
			}}
			onSubmit={(formValues, actions) => {
				onSubmitHandler(formValues);
				setTimeout(() => {
					actions.setSubmitting(false);
				}, 1000);
			}}
			validationSchema={CodeVerifyValidationSchema()}
		>
			{({ values, isSubmitting }) => (
				<Form>
					<Box
						mt={isAboutPage ? '0' : '3rem'}
						minWidth={{ xs: '90%', sm: '45rem' }}
						//maxWidth="90%"
						mx="auto"
					>
						{isAboutPage && (
							<Box>
								<Text fontFamily="Archivo-Bold" fontSize={'h4'} mb="1.6rem">
									Enter Code
								</Text>
								<Text fontSize={'16px'} mb="1.6rem">
									Please enter the code provided by your training provider.
								</Text>
							</Box>
						)}

						<FormInput
							label={isAboutPage ? 'Training Provider Code' : 'Code'}
							name="code"
							autoComplete="off"
						/>

						{isAboutPage ? (
							<Flex>
								{' '}
								<Button loading={loading}>Continue</Button>
								<Button
									type="button"
									ml="1.6rem"
									variant="secondary"
									loading={loading}
									onClick={onCancel}
								>
									Cancel
								</Button>
							</Flex>
						) : (
							<>
								{' '}
								<Button
									width="100%"
									disabled={values.code === '' || isSubmitting}
									loading={loading}
									mt="8px"
								>
									Continue to dashboard
								</Button>
								<Button
									type="button"
									mt="2.4rem"
									variant="secondary"
									width="100%"
									loading={loading}
									onClick={() => {
										onSubmitHandler({ ...values, skip: true });
									}}
								>
									Skip and continue
								</Button>
							</>
						)}
					</Box>
				</Form>
			)}
		</Formik>
	);
};
