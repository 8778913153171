import { FC, useState, useEffect } from 'react';
import { Box, Text } from 'atoms';
import { Avatar } from 'antd';
import moment from 'moment';
// import { Linkify } from 'utils/helpers';
// import parse from 'html-react-parser';
export interface UsersProps {
	message: any;
	currentUserId: string | number;
}

export const ChatItem: FC<UsersProps> = (_props: UsersProps) => {
	const [lastUpdated, setLastUpdated] = useState<string>('');

	useEffect(() => {
		const timer = setInterval(
			() => setLastUpdated(moment(_props?.message?.timestamp).fromNow()),
			1000
		);
		return () => {
			clearInterval(timer);
		};
	}, []);
	const isOwnMessage = _props?.message?.sender?.userId === _props?.currentUserId;
	return (
		<Box display="flex" padding="10px" flexDirection={isOwnMessage ? 'row-reverse' : 'row'}>
			<Avatar size={40}>{_props?.message?.sender?.name?.charAt(0).toUpperCase()}</Avatar>
			<Box paddingX="10px" width="60%">
				<Box
					bg={isOwnMessage ? 'secondary.50' : '#F9FAFB'}
					color={isOwnMessage ? '#F9FAFB' : ''}
					padding="10px"
					borderRadius="5px"
				>
					<Box
						wordBreak="break-all"
						dangerouslySetInnerHTML={{ __html: _props?.message?.message }}
					/>
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Text fontSize="12px">
						{lastUpdated === 'a few seconds ago' ? 'just now' : lastUpdated}
					</Text>
				</Box>
			</Box>
		</Box>
	);
};
