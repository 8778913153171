import { useContext, useState, useEffect, useCallback } from 'react';
import { Tooltip, Badge, Drawer } from 'antd';
import { Button,  Icon, Box } from 'atoms';
import { TextEditor } from 'molecules/TextEditor/TextEditor';
import { ChatPrivilege } from '@zoom/videosdk';
import ZoomContext from '../../../context/zoom-context';
import ChatContext from '../../../context/chat-context';
import { SendOutlined } from '@ant-design/icons';

import { useMount } from '../../../hooks';
import { ChatItem } from './ChatItem';

interface ChatButtonProps {
  className?: string;
  chatOpened?:any
}
const ChatButton = (props: ChatButtonProps) => {
  const zmClient = useContext(ZoomContext);
  const chatClient = useContext(ChatContext);
  const [chatPrivilege, setChatPrivilege] = useState<ChatPrivilege>(ChatPrivilege.All);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState('');
  const [content, setContent] = useState('');
  const [sendTrigger, setSendTrigger] = useState<any>(false);
	const [attachments, setAttachments] = useState<any[]>([]);
	const [initialValue, setInitialValue] = useState<any>({ value: '' });
  
  const {
    className,
    chatOpened
  } = props;

  const onChatMessage = useCallback(
    (payload) => {
      setMessages((lastMessages: any) => [...lastMessages, payload])
      if (payload?.sender?.userId !== zmClient.getSessionInfo().userId) {
        setMessageCount(last=>last+1)
      }
    },[]
  );

  useEffect(() => {
    zmClient.on('chat-on-message', onChatMessage);
    return () => {
      zmClient.off('chat-on-message', onChatMessage);
    };
  }, [zmClient, onChatMessage]);
  useEffect(() => {
		sendTrigger && sendMessage();
		setSendTrigger(false);
	}, [sendTrigger]);

  const sendMessage = () => {
    if (text && String(text).trim() || attachments.length > 0) {
			let newContent: any = content;
			if (attachments.length > 0) {
				attachments.map((attach: any) => {
					newContent += `<a href='${attach.url}' target='_blank'>${attach.name}</a>`;
				});
      }
        chatClient?.sendToAll(newContent);
        setText('');
        setContent('');
        setInitialValue({ value: '' });
        setAttachments([]);
    }
  }

  useMount(() => {
    setCurrentUserId(zmClient.getSessionInfo().userId);
    if (chatClient) {
      setChatPrivilege(chatClient.getPrivilege());
    }
  });

  const showModal = () => {
    setIsModalOpen(true);
    chatOpened(true);
    setMessageCount(0);
  };
  
  const hideModal = () => {
    setIsModalOpen(false);
    chatOpened(false);
  };

  return (
    <div>
      <Tooltip title={`${isModalOpen ? 'Close Chat' : 'Open Chat'}`} color="#ffffff55">
        {
          messageCount > 0 ? (
            <Badge count={messageCount} overflowCount={99} offset={[-13, 5]}>
            <Button
              mx="8px"
              display="flex"
              borderRadius="50%" 
              height="50px !important" 
              width="50px !important"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              variant={`${isModalOpen ? 'danger' : 'dark'}`}
              onClick={showModal}
            >
              <Icon
                name="chat"
                width="24px"
                height="24px"
              />
            </Button>
          </Badge>
          ): (
            <Button
            mx="8px"
            display="flex"
            borderRadius="50%" 
            height="50px !important" 
            width="50px !important"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            variant={`${isModalOpen ? 'danger' : 'dark'}`}
            onClick={showModal}
          >
            <Icon
              name="chat"
              width="24px"
              height="24px"
            />
          </Button>
          )
        }
        
        
      </Tooltip>
      <Drawer
        title="Chat"
        placement="right"
        closable={true}
        onClose={hideModal}
        open={isModalOpen}
        key="right"
      >
        <Box display="flex" height="100%" overflow="hidden" flexDirection="column">
          <Box bg="red" flexGrow="1" overflow="auto" display="flex" flexDirection="column-reverse" >
            <Box>
              {
                messages?.map((message:any, idx:number ) => (
                  <ChatItem message={message} currentUserId={ currentUserId } key={idx} />
                ))
              }
            </Box>
          </Box>  
        <Box padding="10px" className="relative">
          <TextEditor
						initialContent={initialValue}
            heightInPx="100px"
              onContentChange={(value, text) => {
              setText(text);
              setContent(value);
              }}
              handleReturn={(e: any, value: string, text: string) => {
                if (!e.shiftKey && !e.altKey && !e.ctrlKey) {
                  setText(text);
                  setContent(value);
                  setSendTrigger(text);
                }
              }}
            onAttachmentsChanged={(attachments) => {
              setAttachments(attachments);
            }}
            isExternalAttachment={true}
            allowImageUpload={false}
          />
          <Button
            variant="primary"
            fontFamily="semibold"
            px="15px"
            py="10px"
            type="submit"
            className="absolute"
            zIndex="10"
            disabled={text === '' && attachments.length===0}
            bottom="20px"
            right="20px"
            onClick={sendMessage}
          >
            <SendOutlined />
          </Button>
				</Box>
        </Box>
        
      </Drawer>
    </div>
  );
};
export default ChatButton;
