import { themeGet } from '@styled-system/theme-get';
import { Table as AntTable, TableProps as AntTableProps } from 'antd';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import './table.style.css';
import {
	Box,
	//Button,
	Grid,
	//Flex,
	Text,
	Pagination,
} from 'atoms';

import {
	//Avatar,
	List,
	//Skeleton
} from 'antd';

export interface TableProps extends AntTableProps<{}> {
	bold1stColumn?: boolean;
	minColumnWidth?: string;
	meta?: any;
	pageOnChange?: any;
	searchParams?: any;
}

const StyledTable = styled(AntTable)<TableProps>`
	thead {
		th {
			background: white;
			vertical-align: baseline;
			min-width: ${(props) => (props.minColumnWidth ? props.minColumnWidth : '')};
		}
		/* For Reports Training ICF mode Fix */
		th:first-child {
			min-width: ${(props) => (props.minColumnWidth ? 'auto' : '')};
		}
		th,
		span {
			font-family: ${(props) =>
				`${themeGet('fontFamily.semibold', 'Inter-SemiBold')(props)}`};
			color: ${(props) => `${themeGet('colors.primary.50', 'black')(props)}`};
		}
	}
	tbody {
		.ant-table-row {
			&:hover {
				cursor: pointer;
				-webkit-box-shadow: 0px 0px 20px 10px rgba(250, 250, 250, 0.97);
				-moz-box-shadow: 0px 0px 20px 10px rgba(250, 250, 250, 0.97);
				box-shadow: 0px 0px 20px 10px rgba(250, 250, 250, 0.97);
			}
			td {
				color: ${(props) => `${themeGet('colors.primary.150', 'black')(props)}`};
				font-weight: ${({ theme }) => theme.fontWeights['regular']};
				font-size: ${({ theme }) => theme.fontSizes['body1']};
			}
			td:first-child {
				font-weight: ${(props) => (props.bold1stColumn ? 'bold' : 'normal')};
			}
		}
		.ant-table-row-selected {
			td {
				/* background:white; */
				border-color: ${({ theme }) => `0.5px solid ${theme.colors.white['100']}`};
				.ant-checkbox-inner {
					background-color: ${({ theme }) => theme.colors.primary['600']};
					border-color: ${({ theme }) => theme.colors.primary['600']};
				}
			}
		}
	}
`;

export const Table: FC<TableProps> = (props: any) => {
	// let page = props?.pagination?.current;

	// let total = props?.pagination?.total;
	// let data = props?.dataSource;

	// let firstValue = page === 1 ? (data?.length > 0 ? page : 0) : (page - 1) * limit + 1;
	// let secondValue = (page - 1) * limit + data?.length;

	const { meta, pageOnChange, searchParams } = props;

	let limit = parseInt((searchParams.limit || 10).toString()) || 10;
	//let limit = 1;

	const checkResponsive = (arg: any): any => {
		return arg?.availWidth <= 992;
	};

	const isResponsive = useMemo(() => checkResponsive(window.screen), [window.screen]);

	return (
		<>
			{isResponsive ? (
				<Box>
					<Box>
						<List
							className="demo-loadmore-list"
							//loading={initLoading}
							itemLayout="vertical"
							//loadMore={loadMore}
							dataSource={props?.dataSource}
							renderItem={(item: any, itemIndex: number) => (
								<List.Item
									actions={
										[
											// <Flex style={{ gap: '8px' }}>
											// 	<Link to={`/admin/cohorts/view/${item?._id}`}>
											// 		<Button id="view" variant="secondary" className="xs">
											// 			View
											// 		</Button>
											// 	</Link>
											// 	<Link to={`/admin/cohorts/edit/${item?._id}`}>
											// 		<Button id="view" variant="secondary" className="xs">
											// 			Edit
											// 		</Button>
											// 	</Link>
											// 	<Button
											// 		id="delete"
											// 		variant="secondary"
											// 		className="xs"
											// 		color="danger.300"
											// 	>
											// 		Delete
											// 	</Button>
											// </Flex>,
										]
									}
								>
									{/* <Skeleton avatar title={false} loading={item?.loading} active>
									<List.Item.Meta
										avatar={<Avatar src={item?.picture?.large} />}
										title={<a href="https://ant.design">{item.name?.last}</a>}
										description="Ant Design, a design language for background applications, is refined by Ant UED Team"
									/> */}

									<Grid
										gridAutoFlow={{ xs: 'inherit', md: 'column' }}
										gridTemplateColumns={{ sm: '50% 50%', xs: '100%' }}
										gridColumnGap="2rem"
										px="2rem"
										onClick={(e: any) => {
											props.onRow(
												{ ...item, action: e?.target.id },
												itemIndex
											);
										}}
									>
										{props?.columns?.map((x: any, index: number) => {
											return (
												<Box mb="1rem" key={`list${index}`}>
													<Text fontFamily="semibold">{x?.title}</Text>
													{/* {item?.name || ''} */}
													{x?.render(item, item)}
												</Box>
											);
										})}
									</Grid>

									{/* </Skeleton> */}
								</List.Item>
							)}
						/>
					</Box>
					<Box>
						<Pagination
							pageSizeOptions={['10', '20', '50']}
							total={(meta && meta.total) || 0}
							defaultPageSize={limit}
							fetchData={pageOnChange}
						/>
					</Box>
				</Box>
			) : (
				<StyledTable
					pagination={{
						current: parseInt((searchParams.page || 1).toString()) || 1,
						pageSizeOptions: ['10', '20', '50', '100'],
						showSizeChanger: true,
						defaultPageSize: limit,
						total: (meta && meta.total) || 0,
						showTotal: (total, range) => {
							return total > 0
								? `Showing ${range[0]} to ${range[1]} of ${total} results`
								: '';
						},
						className: 'mcp-pagination',
					}}
					{...props}
				/>
			)}
		</>
	);
};
