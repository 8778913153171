import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { message } from 'antd';

import { Text, Box, Icon } from 'atoms';
import SetupAccount from './SetupAccount';
import { useLocationQuery } from 'hooks/useLocationQuery';

import useAuth from 'pages/Auth/context/Authentication';

export const FormContext = createContext({});

type formValuesType = {
	userType: number;
	type: string;
};

function RegisterContinuePage({ userType, type }: formValuesType) {
	const searchParams: any = useLocationQuery();
	const navigate = useNavigate();
	const [roleId, setRoleId] = useState('');

	const {
		state: { roles },
		actions: {
			outsetaRegister,
			switchProtal,
			updateProfile,
			getRoles,
			getRoleId,
			//invitationTokenVerify
		},
	} = useAuth();

	const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

	const [formData, setFormData] = useState<any>({});
	const [serverErrors] = useState<any>([]);

	useEffect(() => {
		getRoles((roles) => {
			getRoleId(roles, searchParams?.type, (roleId) => {
				setRoleId(roleId);
			});
		});
	}, []);

	useEffect(() => {
		if (!searchParams?.confirmationToken) {
			navigate(`/login`);
		}
	}, []);

	function _handleSubmit(values: any, actions: any) {
		if (roleId) {
			outsetaRegister(
				{
					...values,
					...searchParams,
					role: roleId,
				},
				(res) => {
					const coach = res?.user?.roles?.[0]?.name?.toLowerCase() === 'coach';
					localStorage.setItem('isFirstTime', 'true');
					if (coach) {
						switchProtal('coach');
						if (res?.user?.meta?.basicProfileCompleted) {
							//navigate('/coach/home');
							window.location.href = '/coach/home';
						} else {
							window.location.href = '/coach/complete-profile';
							//navigate('/coach/complete-profile');
						}
					} else {
						switchProtal('coachee');
						if (res?.user?.meta?.basicProfileCompleted && !searchParams?.connection) {
							//navigate('/coachee/home');
							window.location.href = '/coachee/home';
						} else if (searchParams?.connection) {
							//window.location.href = `/coachee/complete-profile?connection=${searchParams?.connection}&invitationToken=${searchParams?.invitationToken}&firstName=${searchParams?.firstName}&lastName=${searchParams?.lastName}&invite=true`;
							updateProfile(
								{
									...res.user,
									meta: { basicProfileCompleted: true },
									userType: 'coachee',
								},
								(data) => {
									window.location.href = `/coachee/connections`;
								}
							);

							// invitationTokenVerify({ searchParams }, () => {
							// 	window.location.href = `/coachee/complete-profile?connection=${searchParams?.connection}&invitationToken=${searchParams?.invitationToken}&firstName=${searchParams?.firstName}&lastName=${searchParams?.lastName}&invite=true`;
							// });
						} else {
							//navigate('/coachee/complete-profile');
							window.location.href = `/coachee/complete-profile?organization=${searchParams?.organization}&organizationVertical=${searchParams?.organizationVertical}&firstName=${searchParams?.firstName}&lastName=${searchParams?.lastName}`;
						}
					}
				}
			);
		} else message.error('Role id missing');
	}

	return (
		<FormContext.Provider
			value={{
				activeStepIndex,
				setActiveStepIndex,
				formData,
				setFormData,
				serverErrors,
			}}
		>
			{/* <Text
				fontSize="h4"
				lineHeight="h4"
				color="secondary.50"
				fontFamily="Archivo-Bold"
				mt="0.7rem"
				mb="5.6rem"
			>
				My Coaching Place
			</Text> */}

			<Box mt="0.7rem" mb="5.6rem">
				<Icon name="logo" height="7rem" type={'text'} />
			</Box>

			<Box>
				{searchParams?.via_google ? (
					<Box>Signing </Box>
				) : (
					<SetupAccount onSubmit={_handleSubmit} roleId={roleId} />
				)}
			</Box>
		</FormContext.Provider>
	);
}

export default RegisterContinuePage;
