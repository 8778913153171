import { capitalize } from 'lodash';

export const DEFAULT_FREE_CREDIT = 6;

export enum UserType {
	Client = 1,
	Coach = 2,
	Supervisor = 3,
	Admin = 4,
}

export enum UserStatus {
	DRAFT = 0,
	INVITED = 1,
	INVITE_ACCEPTED = 2,
	ACTIVE = 3,
	INACTIVE = 4,
}

export enum PaymentStatus {
	PAID = 1,
	FAILED = 2,
}

export enum CouponDiscountType {
	Percentage_Discount = 1,
	Flat_Price = 2,
}

export enum UserCategory {
	New_Users = 1,
	Existing_Users = 2,
	Both = 3,
}

export enum UserSubscriptionStatus {
	ACTIVE = 1,
	INACTIVE = 2,
	BLOCKED = 3,
}

export function EnumKeyFromValue<T extends { [index: string]: any }>(
	myEnum: T,
	enumValue: any
): keyof T | null {
	// eslint-disable-next-line
	let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
	return keys.length > 0 ? keys[0] : null;
}

export function EnumToArrayNames<Type>(data: Type) {
	const arrayObjects = [];
	// Retrieve key and values using Object.entries() method.
	for (const [propertyKey, propertyValue] of Object.entries(data)) {
		// Ignore keys that are not numbers
		if (!Number.isNaN(Number(propertyKey))) {
			continue;
		}

		// Add keys and values to array
		arrayObjects.push({
			value: propertyValue,
			label: capitalize(propertyKey)?.replace('_', ''),
		});
	}

	return arrayObjects;
}

export enum RESOURCE_TYPES {
	TEMPLATE = 1,
	JOURNALING = 2,
	SURVEY = 3,
	CONTRACT = 4,
}

export enum RESOURCE_STATUS {
	DRAFT = 1,
	PUBLISHED = 2,
	INACTIVE = 3,
}

export enum TASK_TYPES {
	TEMPLATE = 1,
	JOURNALING = 2,
	SURVEY = 3,
	CONTRACT = 4,
	OTHER = 5,
}

export enum TASK_STATUS {
	DELETED = 0,
	TO_DO = 1,
	IN_PROGRESS = 2,
	COMPLETED = 3,
	SHARED = 4,
	SIGNED_AND_COMPLETED = 5,
}

export const ORGANIZATION_TYPES = {
	CORPORATE: 1,
	CHARITY: 2,
	GOOD_CAUSE: 3,
	INDIVIDUAL: 4,
};

export const CONNECTION_STATUS = {
	ACTIVE: 1,
	INACTIVE: 2,
	INVITED: 3,
	EXPIRED: 5,
	DEACTIVATED: 6,
	COMPLETED: 7,
	DISCONTINUED: 8,
	DECLINED: 9,
	BREAKED: 10,
	REQUESTED: 11,
};
export const ORGANIZATION_VERTICAL_CODE_STATUS = {
	ACTIVE: 1,
	IN_ACTIVE: 2,
};

export const CODE_REDEEM_STATUS = {
	REDEEMED: 1,
	NOT_REDEEMED: 0,
};

export const COUPON_STATUS = {
	ACTIVE: 1,
	INACTIVE: 2,
};

export const COUPON_TYPES = {
	FIXED: 1,
	PERCENTAGE: 2,
};

export const COUPON_APPLICABLE_TYPES = {
	SUBSCRIPTION_MONTHLY: 1,
	SUBSCRIPTION_ANNUALLY: 2,
	BUNDLE_PACKAGE: 3,
};

export const USER_STATUS = {
	VERIFIED: 1,
	NOT_VERIFIED: 2,
};

export const CONNECTION_TYPE = {
	TGC: 0,
	NON_TGC: 1,
};

export const CURRENCIES = {
	DOLLAR: 'USD',
	EURO: 'EUR',
	POUND: 'GBP',
};

export const PLAN_TYPES = {
	SUBSCRIPTION_MONTHLY: 1,
	SUBSCRIPTION_ANNUALLY: 2,
	BUNDLE_PACKAGE: 3,
};

export const PURCHASE_TYPES = {
	NOW: 1,
	LATER: 2,
};

export const CREDIT_TYPES = {
	FREE: 1,
	EARNED: 2,
	PURCHASED: 3,
};

export const COACHEE_REGISTRATION_TYPE = {
	ORGANIZATION_CODE_INVITE: 1,
	CONNECTION_INVITE: 2,
	REPORT_INVITE: 3,
};

export const ATTACHMENT_TYPES = {
	IMAGE: 1,
	DOCUMENT: 2,
	YOUTUBE_URL: 3,
};
