import MemoSearchIcon from 'assets/icons/SearchIcon';
import CloseIcon from 'assets/icons/CloseIcon';
import { Box, Input } from 'atoms';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useLocationQuery, useLocationQuerySet } from 'hooks/useLocationQuery';
import { useSearchQuery } from 'hooks/useSearchQuery';
import React, { useCallback, useEffect, useMemo } from 'react';

export interface SearchProps {
	onSearch?: (text: string | undefined) => void;
	placeholder?: string;
}

export const StyledInput = styled(Input)`
	color: #9ca3af !important;
	input {
		&::placeholder,
		&::-ms-input-placeholder,
		&:-ms-input-placeholder {
			color: #9ca3af !important;
		}
	}
	// &::placeholder,
	// &::-ms-input-placeholder,
	// &:-ms-input-placeholder {
	// 	color: #9ca3af !important;
	// }
	&::placeholder {
		color: #9ca3af !important;
		font-size: 16px;
		font-family: 'Inter-Regular';
	}
`;

export const SearchBar: React.FC<SearchProps> = ({
	onSearch,
	placeholder = 'Type any keyword',
}) => {
	const query = useSearchQuery();

	const searchParams: any = useLocationQuery();
	const setQuery = useLocationQuerySet();

	const changeSearchQuery = useCallback(
		(value?: string) => {
			searchParams.page = '1';
			if (value) {
				searchParams.q = value;
			} else {
				delete searchParams.q;
			}

			setQuery(searchParams);
			if (typeof onSearch === 'function') {
				onSearch(value);
			}
		},
		[setQuery, searchParams, onSearch]
	);

	const debouncedInput = useMemo(() => debounce(changeSearchQuery, 500), [changeSearchQuery]);

	useEffect(() => {
		return debouncedInput.cancel();
	}, [debouncedInput]);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			debouncedInput(e.currentTarget.value);
		},
		[debouncedInput]
	);

	// const onSubmitHandler = useCallback(
	// 	({ search }) => changeSearchQuery(search),
	// 	[changeSearchQuery]
	// );

	return (
		<Box>
			{/* <Formik onSubmit={onSubmitHandler} initialValues={{ search: query }}>
				{({ values: { search }, setFieldValue, submitForm }) => (
					<Form> */}
			<Box>
				<Box position="absolute" top="1.2rem" left="1.6rem">
					<MemoSearchIcon />
				</Box>
				<StyledInput
					placeholder={placeholder}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleChange(e);
						//setSearhValue(e?.target?.value || '');
						/** when input search value is empty once again submit the form */
						//if (e.target.value === '') {
						//submitForm();
						//}
					}}
					height="4.1rem"
					fontFamily={'regular'}
					//width="20vw"
					//color="grey.100"
					fontSize={'1.6rem'}
					defaultValue={query || undefined}
					//value={searchValue}
					pl="4.5rem"
					id="search-bar"
				/>

				{searchParams?.q?.length > 0 && (
					<Box
						position="absolute"
						top="1.2rem"
						right="1.4rem"
						cursor="pointer"
						onClick={() => {
							changeSearchQuery('');

							let inputValue = document.getElementById(
								'search-bar'
							) as HTMLInputElement;
							if (inputValue) {
								inputValue.value = '';
							}
						}}
					>
						<CloseIcon stroke="#9CA3AF" width="1rem" />
					</Box>
				)}
			</Box>
			{/* </Form>
				)}
			</Formik> */}
		</Box>
	);
};
