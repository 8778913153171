import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from 'routes';
import useAuth from './context/Authentication';

export type AuthGateProps = {};

/**
 * If we want hide freshdesk chat on certain pages means add that page url here
 */
const hideRoutes = ['/conference/', '/login'];

declare const window: Window &
	typeof globalThis & {
		FB: any;
	};

export const AuthGate: FC<AuthGateProps> = () => {
	const {
		state: { isLoggedIn },
	} = useAuth();

	const location = useLocation();

	const hideChat = useMemo(() => {
		/**
		 * to check if chat widget is need to hide or not
		 */
		return hideRoutes.some((item: string) => location.pathname.includes(item));
	}, [location.pathname]);

	useEffect(() => {
		if (process.env.REACT_APP_ENV === 'production') {
			try {
				setTimeout(() => {
					if ((window as any).document.getElementById('fc_frame')) {
						(window as any).document.getElementById('fc_frame').style.display = hideChat
							? 'none'
							: 'block';
					} else {
						console.log('cant find dom');
					}
				}, 3000);
			} catch (error) {
				console.log('document DOM error => ', error);
			}
		}
	}, [location.pathname, hideChat]);

	if (isLoggedIn || !!localStorage.getItem('token')) {
		return <AuthenticatedRoutes />;
	}
	return <UnauthenticatedRoutes />;
};
