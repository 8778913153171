import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import { Box, Grid, Text, Flex, Image, Button, Icon } from 'atoms';
import { Modal } from 'antd';
import CodeVerifyForm from 'templates/CodeVerifyForm';
import { useLocationQuery } from 'hooks/useLocationQuery';
import SubscriptionGroup from 'assets/images/SubscriptionGroup.png';
import { DEFAULT_FREE_CREDIT } from 'typings/enums/users';

import useAuth from '../context/Authentication';
import useSchools from 'pages/Schools/context/schools';

export type CodeVerifyProps = {};

export const CodeVerify: FC<CodeVerifyProps> = () => {
	const navigate = useNavigate();
	const searchParams: any = useLocationQuery();
	const url: any = searchParams.continue;

	const [showSubscriptionModal, setShowSubscriptonModal] = useState<boolean>(false);

	const [schoolData, setSchoolData] = useState<any>(null);

	const {
		state: { currentPortal, user },
		actions: { updateProfile, switchProtal, schoolCodeVerify },
	} = useAuth();

	const {
		actions: { getOne },
	} = useSchools();

	// useEffect(() => {
	// 	if (user?.meta?.schoolCodeVerified) {
	// 		navigate(`/${currentPortal}/home`);
	// 	}
	// }, [user, currentPortal, navigate]);

	const redirection = () => {
		const coach = currentPortal === 'coach';
		// Check the highest profile users higher profile is coach.
		if (coach) {
			switchProtal('coach');
			navigate('/coach/home');
		} else {
			switchProtal('coachee');
			navigate('/coachee/home');
		}
	};

	const completeProfile = useCallback(
		(values) => {
			if (values?.skip) {
				// getOne(user?.school, (data) => {
				// 	if (data?.school) {
				// 		setSchoolData(data?.school);
				// 		setTimeout(() => {
				// 			setShowSubscriptonModal(true);
				// 		});
				// 	}
				// });
				setShowSubscriptonModal(true);
				// const coach = currentPortal === 'coach';
				// // Check the highest profile users higher profile is coach.
				// if (coach) {
				// 	switchProtal('coach');
				// 	navigate('/coach/home');
				// } else {
				// 	switchProtal('coachee');
				// 	navigate('/coachee/home');
				// }
			} else {
				schoolCodeVerify(
					{
						...values,
						userType: currentPortal,
						userId: user?._id,
					},
					(data: any) => {
						setSchoolData(data);
						updateProfile({ meta: { ...user.meta, schoolCodeVerified: true } }, () => {
							setShowSubscriptonModal(true);
							// const coach = currentPortal === 'coach';
							// if (coach) {
							// 	switchProtal('coach');
							// 	navigate('/coach/home');
							// } else {
							// 	switchProtal('coachee');
							// 	navigate('/coachee/home');
							// }
						});
					}
				);
			}
		},
		// eslint-disable-next-line
		[updateProfile, navigate, searchParams.continue, switchProtal, url]
	);

	return (
		<Box>
			<Box mb="2rem">
				{/* <Text
					fontSize="h4"
					lineHeight="h4"
					color="secondary.50"
					fontFamily="Archivo-Bold"
					my="0.7rem"
					mb="5rem"
				>
					My Coaching Place
				</Text> */}

				<Icon name="logo" height="7rem" type={'text'} />
			</Box>
			<Text
				fontSize="23px"
				color="primary.50"
				my="0.7rem"
				fontFamily="Archivo-Bold"
				mb="2rem"
			>
				Enter your code
			</Text>
			<Text fontSize="1.6rem">Please enter the code provided by your training provider</Text>

			<Text fontSize="1.6rem" mt="1.2rem">
				Haven't got a code? Check the information received from your training provider or{' '}
				<a href="mailto:support@mcp.com">
					<Text as="span" color="secondary.50" fontFamily="semibold">
						contact the admin here
					</Text>
				</a>
				.
			</Text>

			<CodeVerifyForm onSubmit={completeProfile} userType={currentPortal} />
			<Modal
				open={showSubscriptionModal}
				onCancel={() => {
					setShowSubscriptonModal(false);
				}}
				footer={false}
				width={650}
			>
				<Box p={'12px'}>
					<Flex justifyContent={'center'}>
						<Image src={SubscriptionGroup} alt="Subscription group" width="308px" />
					</Flex>
					<Text fontFamily="Archivo-Bold" fontSize="h4" my="1rem">
						Free Credits - Our gift to you
					</Text>
					<Text fontSize={'p1'} mb="1.5rem">
						As a special welcome gift, we are giving you{' '}
						<Text as="span" fontFamily={'bold'} color="secondary.50">
							{schoolData?.meta?.numberOfFreeCredits || DEFAULT_FREE_CREDIT || 0}{' '}
							session credits
						</Text>{' '}
						so you can use My Coaching Place with your own clients and really get to
						know the features and benefits. Here's how the session credits work:
					</Text>
					<Grid
						border="1px solid #D1D5DB"
						p="2rem"
						gridTemplateColumns={{ xs: '100%', sm: ' 1fr 1fr' }}
					>
						<Box>
							<Text fontFamily={'semibold'}>Feature</Text>
							<Text my="1.6rem">Onboarding a client +1 session *</Text>
							<Text>Scheduling a session *</Text>
						</Box>
						<Box>
							<Text fontFamily={'semibold'}>Session Credits Needed</Text>
							<Text my="1.6rem">1 session credit</Text>
							<Text>1 session credit</Text>
						</Box>
					</Grid>

					<Text fontSize={'p1'} my="1.5rem">
						*Session summary and feedback, messaging, file sharing, assigning templates,
						session logs, reflections, and agreement signing are included.
					</Text>
					<Button
						onClick={() => {
							redirection();
						}}
					>
						Continue
					</Button>
				</Box>
			</Modal>
		</Box>
	);
};
