export const MALE_PLACEHOLDER = require(`assets/images/male.png`);
export const FEMALE_PLACEHOLDER = require(`assets/images/female.png`);
import { ASSET_ENDPOINT } from 'settings/constants';
import { CURRENCIES } from 'typings/enums/users';

//https://stackoverflow.com/questions/18681788/how-to-get-a-youtube-thumbnail-from-a-youtube-iframe
//https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
//http://jsfiddle.net/soboaz/rK8c4/
export const getYoutubeVideoId = (videoId: string) => {
	const rx =
		/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/; //eslint-disable-line

	let id = videoId?.match(rx);
	return id?.[1]
		? `//img.youtube.com/vi/${id?.[1]}/0.jpg`
		: "//img.youtube.com/vi/'+youtube_video_id+'/0.jpg";
};

export const AboutPageRequiredFields = [
	'firstName',
	'lastName',
	'email',
	'phone',
	'images',
	'dob',
	'pronoun',
	'school',
	'cohort',
];

export const MatchingPageRequiredFields = [
	'capacity',
	'supervision',
	'mentoring',
	'matchingInterested',
	'languages',
	'timezone',
];

export const ExperiencePageRequiredFields = [
	'sectorExperience',
	'seniorExperience',
	'experienceInYears',
	'expectedAreas',
];

export const checkprofileCompleted = (currentPortal: string, user: any, school?: any) => {
	let AboutPageFields = AboutPageRequiredFields;

	let MatchingPageFields = MatchingPageRequiredFields;

	let ExperiencePageFields = ExperiencePageRequiredFields;

	if (currentPortal === 'coachee') {
		delete AboutPageFields[4];
		delete AboutPageFields[7];
		delete AboutPageFields[8];

		delete MatchingPageFields[0];
		delete MatchingPageFields[1];
		delete MatchingPageFields[2];
	} else {
		delete AboutPageFields[3];
		delete AboutPageFields[4];
		delete AboutPageFields[5];
		delete AboutPageFields[6];
	}

	let AboutPagePercentage: any = AboutPageFields?.filter(
		(x: any) => !(user[x] || user?.meta[x])
	)?.length;

	let MatchingPagePercentage: any = MatchingPageFields?.filter(
		(x: any) =>
			user?.preference?.[currentPortal]?.[x] === null ||
			user?.preference?.[currentPortal]?.[x] === undefined ||
			user?.preference?.[currentPortal]?.[x] === ''
	)?.length;

	let ExperiencePagePercentage = ExperiencePageFields?.filter(
		(x: any) =>
			user?.preference?.[currentPortal]?.[x] === null ||
			user?.preference?.[currentPortal]?.[x] === undefined ||
			user?.preference?.[currentPortal]?.[x] === ''
	)?.length;

	let total = AboutPagePercentage + MatchingPagePercentage + ExperiencePagePercentage;

	// Coach
	if (currentPortal === 'coach') {
		if (user?.meta?.schoolCodeVerified && total === 0) {
			return true;
		}
		return false;
	} else {
		return total === 0;
	}
};

export const findPercentage = (currentPortal: string, user: any) => {
	let AboutPageFields = AboutPageRequiredFields;

	let MatchingPageFields = MatchingPageRequiredFields;

	let ExperiencePageFields = ExperiencePageRequiredFields;

	if (currentPortal === 'coachee') {
		delete AboutPageFields[7];
		delete AboutPageFields[8];

		delete MatchingPageFields[0];
		delete MatchingPageFields[1];
		delete MatchingPageFields[2];
	}

	let AboutPagePercentage: any = AboutPageFields?.filter(
		(x: any) => user[x] || user?.meta[x]
	)?.length;

	AboutPagePercentage = (AboutPagePercentage / AboutPageFields?.length) * 100;

	if (
		currentPortal === 'coachee' ||
		(currentPortal === 'coach' && user?.meta?.schoolCodeVerified)
	) {
		let MatchingPagePercentage: any = MatchingPageFields?.filter(
			(x: any) => user?.preference?.[currentPortal]?.[x]
		)?.length;

		MatchingPagePercentage = (MatchingPagePercentage / MatchingPageFields?.length) * 100;

		let ExperiencePagePercentage = ExperiencePageFields?.filter(
			(x: any) => user?.preference?.[currentPortal]?.[x]
		)?.length;

		ExperiencePagePercentage = (ExperiencePagePercentage / ExperiencePageFields?.length) * 100;

		let total = (AboutPagePercentage + MatchingPagePercentage + ExperiencePagePercentage) / 3;

		return total;
	}

	return AboutPagePercentage;
};

export const profilePlaceholder = (user: any, isMessage = false) => {
	if (isMessage) {
		return user?.profileImage
			? ASSET_ENDPOINT + user?.profileImage
			: user?.meta?.pronoun === 'Female'
			? FEMALE_PLACEHOLDER
			: MALE_PLACEHOLDER;
	}

	return user?.images?.[0] || user?.images?.fileName
		? `${ASSET_ENDPOINT}${user?.images?.[0]}`
		: user?.meta?.pronoun === 'Female'
		? FEMALE_PLACEHOLDER
		: MALE_PLACEHOLDER;
};

export const PriceFormat = (currentType: any, price?: any) => {
	let symbol = currentType === CURRENCIES?.DOLLAR ? '$' : '£';
	return `${symbol}${price ? price : 0}`;
};
