import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import ZoomVideo, { TestMicrophoneReturn, TestSpeakerReturn } from '@zoom/videosdk';
import { useMount } from '../../hooks';
import './preview.scss';
// import MicrophoneButton from '../video/components/microphone';
// import CameraButton from '../video/components/camera';
import useSessions from 'pages/Session/context/sessions';
import useAuth from 'pages/Auth/context/Authentication';

import { message, Progress, Select, Modal, Tooltip } from 'antd';
import { Button, Icon, Text } from 'atoms';
import { Box, Flex } from 'atoms';
import { MediaDevice } from '../video/video-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// label: string;
// deviceId: string;
let prevMicFeedbackStyle = '';
let micFeedBackInteval: any = '';

let localAudio = ZoomVideo.createLocalAudioTrack();
let localVideo = ZoomVideo.createLocalVideoTrack();
let allDevices;

const mountDevices: () => Promise<{
	mics: MediaDevice[];
	speakers: MediaDevice[];
	cameras: MediaDevice[];
}> = async () => {
	allDevices = await ZoomVideo.getDevices();
	const cameraDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
		return device.kind === 'videoinput';
	});
	const micDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
		return device.kind === 'audioinput';
	});
	const speakerDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
		return device.kind === 'audiooutput';
	});
	return {
		mics: micDevices.map((item) => {
			return { label: item.label, deviceId: item.deviceId };
		}),
		speakers: speakerDevices.map((item) => {
			return { label: item.label, deviceId: item.deviceId };
		}),
		cameras: cameraDevices.map((item) => {
			return { label: item.label, deviceId: item.deviceId };
		}),
	};
};

const AUDIO_MASK = 1;
const MIC_MASK = 2;
const VIDEO_MASK = 4;

let PREVIEW_VIDEO: any;

const updateMicFeedbackStyle = () => {
	const newVolumeIntensity = localAudio.getCurrentVolume();
	let newMicFeedbackStyle = '';

	if (newVolumeIntensity === 0) {
		newMicFeedbackStyle = '';
	} else if (newVolumeIntensity <= 0.05) {
		newMicFeedbackStyle = 'mic-feedback__very-low';
	} else if (newVolumeIntensity <= 0.1) {
		newMicFeedbackStyle = 'mic-feedback__low';
	} else if (newVolumeIntensity <= 0.15) {
		newMicFeedbackStyle = 'mic-feedback__medium';
	} else if (newVolumeIntensity <= 0.2) {
		newMicFeedbackStyle = 'mic-feedback__high';
	} else if (newVolumeIntensity <= 0.25) {
		newMicFeedbackStyle = 'mic-feedback__very-high';
	} else {
		newMicFeedbackStyle = 'mic-feedback__max';
	}
	const micIcon: any = document.getElementById('auido-volume-feedback');
	if (prevMicFeedbackStyle !== '' && micIcon) {
		micIcon.classList.toggle(prevMicFeedbackStyle);
	}

	if (newMicFeedbackStyle !== '' && micIcon) {
		micIcon.classList.toggle(newMicFeedbackStyle);
	}
	console.log(newMicFeedbackStyle, newVolumeIntensity);
	prevMicFeedbackStyle = newMicFeedbackStyle;
};

const encodePreviewOptions = (
	isStartedAudio: boolean,
	isMuted: boolean,
	isStartedVideo: boolean
) => {
	let res = 0;
	res = (res | Number(isStartedVideo)) << 1;
	res = (res | Number(isMuted)) << 1;
	res = res | Number(isStartedAudio);
	return res;
};
const decodePreviewOptions = (val: number) => {
	/*
      LSB: audio,
      MSB: video
   */
	const isStartedAudio = !!((val & AUDIO_MASK) === AUDIO_MASK);
	const isMuted = !!((val & MIC_MASK) === MIC_MASK);
	const isStartedVideo = !!((val & VIDEO_MASK) === VIDEO_MASK);
	return { isStartedVideo, isMuted, isStartedAudio };
};
const { Option } = Select;

const PreviewContainer = () => {
	const {
		state: { currentSession },
		actions: { getSessionById, updateSession },
	} = useSessions();

	const {
		state: { currentPortal: userType, meetingNotification },
	} = useAuth();

	const navigate = useNavigate();
	const { id }: any = useParams();
	const { state } = useLocation();
	const url = window.location.pathname;
	const sessionId = url.substring(url.lastIndexOf('/') + 1);

	const [user, setuser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [session, setSession] = useState<any>(null);

	const [isStartedAudio, setIsStartedAudio] = useState<boolean>(false);
	const [isMuted, setIsMuted] = useState<boolean>(true);
	const [isStartedVideo, setIsStartedVideo] = useState<boolean>(false);
	const [micList, setMicList] = useState<MediaDevice[]>([]);
	const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
	const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
	const [activeMicrophone, setActiveMicrophone] = useState('');
	const [activeSpeaker, setActiveSpeaker] = useState('');
	const [activeCamera, setActiveCamera] = useState('');
	const [outputLevel, setOutputLevel] = useState(0);
	const [inputLevel, setInputLevel] = useState(0);
	const [isPlayingAudio, setIsPlayingAudio] = useState(false);
	const [isRecordingVoice, setIsRecordingVoice] = useState(false);
	const [isPlayingRecording, setIsPlayingRecording] = useState(false);
	const speakerTesterRef = useRef<TestSpeakerReturn>();
	const microphoneTesterRef = useRef<TestMicrophoneReturn>();
	const [visible, setVisible] = useState(false);
	const [notified, setNotified] = useState(false);

	const stopFunctions = async () => {
		if (isStartedVideo) {
			try {
				await localVideo?.stop();
			} catch (e) {
				console.log(e);
			}
		}
		if (isStartedAudio) {
			try {
				await localAudio?.stop();
			} catch (e) {
				console.log(e);
			}
		}
	};

	const onCameraClick = useCallback(async () => {
		if (isStartedVideo) {
			await localVideo?.stop();
			setIsStartedVideo(false);
		} else {
			await localVideo?.start(PREVIEW_VIDEO);
			setIsStartedVideo(true);
		}
	}, [isStartedVideo]);

	const onMicrophoneClick = useCallback(async () => {
		if (isStartedAudio) {
			if (isMuted) {
				await localAudio?.unmute();
				micFeedBackInteval = setInterval(updateMicFeedbackStyle, 500);
				setIsMuted(false);
			} else {
				await localAudio?.mute();
				if (micFeedBackInteval) {
					clearInterval(micFeedBackInteval);
				}
				setIsMuted(true);
			}
		} else {
			await localAudio?.start();
			setIsStartedAudio(true);
		}
	}, [isStartedAudio, isMuted]);

	const hide = () => {
		setVisible(false);
	};
	const show = () => {
		setVisible(true);
	};

	useEffect(() => {
		if (sessionId !== '' && !session) {
			getSessionById(sessionId);
		}
	}, [sessionId, loading]);

	useEffect(() => {
		setSession(currentSession);
		setuser(userType == 'coach' ? currentSession.coach : currentSession.coachee);
		setLoading(false);
	}, [currentSession]);

	useEffect(() => {
		if (meetingNotification) {
			console.log('meetingNotification: ', meetingNotification);
			if (meetingNotification?.userId && meetingNotification?.userId !== user?._id) {
				setNotified(false);
			}
			if (meetingNotification == 'accepted') {
				stopFunctions();
				setTimeout(() => {
					navigate(`/meeting/video/${id}`, {
						state: {
							...state,
							isMutedInit: isMuted,
							isStartedVideoInit: isStartedVideo,
							target: 'video',
						},
					});
				}, 500);
			} else if (meetingNotification == 'declined') {
				setNotified(false);
			}
		}
	}, [meetingNotification]);

	useEffect(() => {
		const encodeVal = encodePreviewOptions(isStartedAudio, isMuted, isStartedVideo);
		// console.log('preview encode val', encodeVal);
		const decodeOption = decodePreviewOptions(encodeVal);
		// console.log('preview config', decodePreviewOptions(encodeVal));
		// message.info(JSON.stringify(decodeOption, null, 2));
		console.log(micList);
	}, [isStartedAudio, isMuted, isStartedVideo]);

	useMount(() => {
		PREVIEW_VIDEO = document.getElementById('js-preview-video');
		mountDevices().then(async (devices) => {
			setMicList(devices.mics);
			setCameraList(devices.cameras);
			setSpeakerList(devices.speakers);
			if (devices.cameras.length > 0) {
				try {
					await localVideo?.start(PREVIEW_VIDEO);
					setIsStartedVideo(true);
				} catch (e) {
					console.log("Couldn't start Video: ", e);
				}
			}
			if (devices.speakers.length > 0) {
				setActiveSpeaker(devices.speakers[0].deviceId);
			}
			if (devices.mics.length > 0) {
				setActiveMicrophone(devices.mics[0].deviceId);

				try {
					await localAudio?.start();
					setIsStartedAudio(true);
					try {
						await localAudio?.unmute();
						setIsMuted(false);
					} catch (e) {
						console.log("Couldn't Unmute: ", e);
					}
				} catch (e) {
					console.log("Couldn't start Audio: ", e);
				}
			}
		});
	});

	const onTestSpeakerClick = () => {
		if (microphoneTesterRef.current) {
			microphoneTesterRef.current.destroy();
			microphoneTesterRef.current = undefined;
		}
		if (isPlayingAudio) {
			speakerTesterRef.current?.stop();
			setIsPlayingAudio(false);
			setOutputLevel(0);
		} else {
			speakerTesterRef.current = localAudio.testSpeaker({
				speakerId: activeSpeaker,
				onAnalyseFrequency: (value) => {
					setOutputLevel(Math.min(100, value));
				},
			});
			setIsPlayingAudio(true);
		}
	};
	const onTestMicrophoneClick = () => {
		if (speakerTesterRef.current) {
			speakerTesterRef.current.destroy();
			speakerTesterRef.current = undefined;
		}
		if (!isPlayingRecording && !isRecordingVoice) {
			microphoneTesterRef.current = localAudio.testMicrophone({
				microphoneId: activeMicrophone,
				speakerId: activeSpeaker,
				recordAndPlay: true,
				onAnalyseFrequency: (value) => {
					setInputLevel(Math.min(100, value));
				},
				onStartRecording: () => {
					setIsRecordingVoice(true);
				},
				onStartPlayRecording: () => {
					setIsRecordingVoice(false);
					setIsPlayingRecording(true);
				},
				onStopPlayRecording: () => {
					setIsPlayingRecording(false);
				},
			});
		} else if (isRecordingVoice) {
			microphoneTesterRef.current?.stopRecording();
			setIsRecordingVoice(false);
		} else if (isPlayingRecording) {
			microphoneTesterRef.current?.stop();
			setIsPlayingRecording(false);
		}
	};
	let microphoneBtn = 'Test Microphone';
	if (isRecordingVoice) {
		microphoneBtn = 'Recording';
	} else if (isPlayingRecording) {
		microphoneBtn = 'Playing';
	}

	return (
		<>
			<Flex
				height="100vh"
				flexDirection="column"
				justifyContent={{ md: 'center', xs: 'auto' }}
			>
				<Flex maxWidth="1550px" flexDirection={{ md: 'row', xs: 'column' }} mx="auto">
					<Box p="32px" position="relative">
						<video
							id="js-preview-video"
							className="preview-video"
							muted={true}
							data-video="0"
						/>
						{!isStartedVideo && (
							<Text
								fontSize={{ md: 'h3', xs: 'h4' }}
								textAlign="center"
								fontWeight="bold"
								color="#ffffff"
								position="absolute"
								top="calc( 50% - 16px)"
								left="calc( 50% - 79px)"
							>
								Camera is off
							</Text>
						)}
						<Flex
							position="absolute"
							left="0"
							right="0"
							bottom="50px"
							justifyContent="center"
						>
							<Tooltip
								title={
									isStartedAudio ? (isMuted ? 'Unmute' : 'Mute') : 'Start Audio'
								}
								color="#ffffff55"
							>
								<Button
									mx="8px"
									display="flex"
									borderRadius="50%"
									height="50px !important"
									width="50px !important"
									justifyContent="center"
									alignItems="center"
									cursor="pointer"
									variant={`${isMuted ? 'danger' : 'dark'}`}
									onClick={onMicrophoneClick}
								>
									<Icon
										name="audio"
										type={`${!isMuted ? 'on' : 'off'}`}
										stroke="#ffffff"
										width="24px"
										height="24px"
									/>
								</Button>
							</Tooltip>
							<Tooltip
								title={isStartedVideo ? 'Stop Camera' : 'Start Camera'}
								color="#ffffff55"
							>
								<Button
									mx="8px"
									display="flex"
									borderRadius="50%"
									height="50px !important"
									width="50px !important"
									justifyContent="center"
									alignItems="center"
									cursor="pointer"
									variant={`${!isStartedVideo ? 'danger' : 'dark'}`}
									onClick={onCameraClick}
								>
									<Icon
										name="video"
										type={`${isStartedVideo ? 'on' : 'off'}`}
										fill="#ffffff"
										width="24px"
										height="24px"
									/>
								</Button>
							</Tooltip>
						</Flex>
						<Box
							display={{ xs: 'none', md: 'block' }}
							position="absolute"
							right="40px"
							bottom="50px"
						>
							<Tooltip title="Settings" color="#ffffff55">
								<Button
									// className={classNames('vc-button', className)}
									display="flex"
									borderRadius="50%"
									height="50px !important"
									width="50px !important"
									justifyContent="center"
									alignItems="center"
									cursor="pointer"
									variant="dark"
									onClick={show}
								>
									<Icon
										name="settings"
										stroke="#ffffff"
										width="24px"
										height="24px"
									/>
								</Button>
							</Tooltip>
						</Box>
					</Box>
					<Flex width="min(100vw, 100%)" flexDirection="column" justifyContent="center">
						<Box p="32px">
							{session?.title && (
								<Text
									fontSize={{ md: 'h3', xs: 'h4' }}
									textAlign="center"
									fontWeight="bold"
									mb="8px"
								>
									{session?.title}
								</Text>
							)}
							{(session?.coach || session?.coachee) && (
								<Text
									fontSize={{ md: 'h4', xs: 'p' }}
									textAlign="center"
								>{`Your session with ${
									userType == 'coach'
										? session?.coachee?.firstName
										: session?.coach?.firstName
								} will start shortly `}</Text>
							)}

							<Flex justifyContent="center">
								{(session?.coach || session?.coachee) && state?.userType && (
									<Button
										mt="32px"
										disabled={notified}
										onClick={() => {
											if (state?.userType == 'coach') {
												stopFunctions();
												setTimeout(() => {
													navigate(`/meeting/video/${id}`, {
														state: {
															...state,
															isMutedInit: isMuted,
															isStartedVideoInit: isStartedVideo,
															target: 'video',
														},
													});
												}, 500);
											} else {
												let payload = {
													status: 1,
												};
												if (state?.status !== 5)
													updateSession(sessionId, payload);
												setNotified(true);
											}
										}}
									>
										{state?.userType == 'coach'
											? 'Join now'
											: 'Notify the Coach'}
									</Button>
								)}
							</Flex>
						</Box>
					</Flex>
				</Flex>
			</Flex>
			<Modal width="600px" onCancel={hide} centered open={visible} footer={false}>
				<Box p="32px 0">
					<Text as="h2" fontWeight="semibold">
						Settings
					</Text>
					<div className="audio-test">
						<div className="audio-test-wrap">
							<h3>Speaker Test</h3>
							<div className="speaker-action">
								<Button onClick={onTestSpeakerClick} className="speaker-btn">
									{isPlayingAudio ? 'Stop' : 'Test Speaker'}
								</Button>
								<Select
									onChange={(value) => {
										setActiveSpeaker(value);
									}}
									value={activeSpeaker}
									className="speaker-list"
								>
									{speakerList.map((item) => {
										return (
											<Option value={item.deviceId} key={item.deviceId}>
												{item.label}
											</Option>
										);
									})}
								</Select>
							</div>
							<div className="speaker-output">
								<span className="speaker-label">Output level</span>
								<Progress percent={outputLevel} showInfo={false} steps={15} />
							</div>
						</div>
						<div className="audio-test-wrap">
							<h3>Microphone Test</h3>
							<div className="speaker-action">
								<Button onClick={onTestMicrophoneClick} className="speaker-btn">
									{microphoneBtn}
								</Button>
								<Select
									onChange={(value) => {
										setActiveMicrophone(value);
									}}
									value={activeMicrophone}
									className="speaker-list"
								>
									{micList.map((item) => {
										return (
											<Option value={item.deviceId} key={item.deviceId}>
												{item.label}
											</Option>
										);
									})}
								</Select>
							</div>
							<div className="speaker-output">
								<span className="speaker-label">Input level</span>
								<Progress percent={inputLevel} showInfo={false} steps={15} />
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default PreviewContainer;
