import { message, Popconfirm } from 'antd';
import MemoCloseIcon from 'assets/icons/CloseIcon';
import { Icons } from 'assets/icons/TextEditor';
import { Box, Flex, Text } from 'atoms';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { handleRegularUpload } from 'hooks/useUploader';
import htmlToDraft from 'html-to-draftjs';
import { debounce } from 'lodash';
import useAuth from 'pages/Auth/context/Authentication';
import React, { useEffect, useMemo } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ASSET_ENDPOINT } from 'settings/constants';
import { randomString } from 'utils/helpers';
import './style.css';

export interface TextEditorProps {
	onContentChange?: (content: string, plainText: string) => void;
	handleReturn?: any;
	initialContent: any;
	isExternalAttachment?: boolean;
	isEmbedableAttachment?: boolean;
	allowImageUpload?: boolean;
	onAttachmentsChanged?: (attachment: any) => void;
	initialAttachments?: any;
	heightInPx?: string;
	backgroundColor?: string;
	disabled?: boolean;
	isFocused?: boolean;
}

const handleUpload = (file: any, userId: string, maxSize: number = 2.5) => {
	return new Promise((resolve, reject) => {
		message.loading(`Uploading...`);

		let userID = userId.replace(/^"|"$/g, '');

		let relativePath = `${userID ? `users/${userID}/attachments/` : `unauth/attachments/`}`;

		handleRegularUpload(
			file,
			{
				maxSize,
				fileName: file.name.split('.')[0],
				relativePath,
			},
			(error: any, response: any) => {
				if (error) {
					reject(error);
				} else {
					resolve({
						data: {
							link: ASSET_ENDPOINT + response.key + `?ts=${Date.now()}`,
						},
					});
				}
			}
		);
	});
};

export const TextEditor: React.FC<TextEditorProps> = ({
	initialContent,
	onContentChange = (e: string) => {},
	handleReturn,
	onAttachmentsChanged = (files: any) => {},
	isExternalAttachment = false,
	allowImageUpload = true,
	initialAttachments = [],
	heightInPx = '200px',
	backgroundColor = 'transparent',
	disabled = false,
	isFocused = false,
}: TextEditorProps) => {
	const [editorValue, setEditorValue]: any = React.useState(EditorState.createEmpty());

	const {
		state: { userId },
	} = useAuth();

	const [attachment, addAttachments]: any = React.useState([]);

	const attachmentRef: any = React.useRef(null);

	const onChange = (editorValueData: any) => {
		onContentChange(
			draftToHtml(convertToRaw(editorValueData.getCurrentContent())),
			editorValueData.getCurrentContent().getPlainText()?.trim()
		);
	};

	const onHandleFileAttachment = (files: any) => {
		if (files && files.length > 0) {
			const file = files[0];
			handleUpload(file, userId)
				.then((res: any) => {
					addAttachments([
						...attachment,
						{
							name: file.name,
							url: res.data.link,
							id: randomString(15),
						},
					]);
				})
				.catch((e) => {});
		}
	};

	useEffect(() => {
		if (isFocused) {
			attachmentRef?.current?.focus();
		}
	}, [isFocused]);

	React.useEffect(() => {
		onAttachmentsChanged(attachment);
		return () => {
			// setEditorValue(EditorState.createEmpty());
		};
	}, [attachment]);

	const onRemoveFileAttachment = (item: any) => {
		const filteredAttachment = attachment.filter((x: any) => x.id !== item.id);
		addAttachments([...filteredAttachment]);
	};

	const debouncedInput = useMemo(() => debounce(onChange, 200), [onChange]);

	React.useEffect(() => {
		return debouncedInput.cancel();
	}, [debouncedInput]);

	useEffect(() => {
		const contentBlock = htmlToDraft(initialContent?.value ?? initialContent ?? '');
		addAttachments(initialAttachments);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			setEditorValue(editorState);
			debouncedInput(editorState);
		}
	}, [initialContent]);

	return (
		<Box
			position="relative"
			className="Test"
			pb={{
				xs: '80px',
				md: '60px',
			}}
			border="1px solid #9CA3AF"
			boxShadow="0px 1px 2px rgba(0, 0, 0, 0.05)"
			borderRadius="5px"
		>
			<Editor
				readOnly={disabled}
				editorStyle={{
					height: heightInPx,
					backgroundColor: backgroundColor,
					borderRadius: '4px',
					padding: '5px',
					fontFamily: 'Inter-Regular',
				}}
				//handlePastedText={() => false}
				editorState={editorValue}
				defaultEditorState={editorValue}
				toolbar={{
					options: [
						'inline',
						'textAlign',
						'list',
						'colorPicker',
						//'link',
						...(allowImageUpload ? ['image'] : []),
					],
					colorPicker: {
						options: ['text'],
						className: 'demo-option-custom',
						popupClassName: 'demo-popup-custom',
					},
					inline: {
						options: ['bold', 'italic', 'underline'],
						italic: { icon: Icons.Italic },
						underline: { icon: Icons.Underline },
					},
					textAlign: {
						options: ['left', 'right', 'center'],
						center: { icon: Icons.CenterAlign },
						right: { icon: Icons.LeftAlign },
						left: { icon: Icons.RightAlign },
					},
					list: {
						options: ['unordered', 'ordered'],
						unordered: { icon: Icons.UnOrderedList },
						ordered: { icon: Icons.OrderedList },
					},
					// link: {
					// 	options: ['link', 'unlink'],
					// 	link: {
					// 		icon: Icons.Link,
					// 	},
					// 	unlink: {
					// 		icon: Icons.Unlink,
					// 	},
					// },
					image: {
						uploadEnabled: true,
						uploadCallback: (file: any) => {
							return handleUpload(file, userId);
						},
						previewImage: true,
						inputAccept: 'image/*',
						alt: { present: false, mandatory: false },
						defaultSize: {
							height: '300px',
							width: 'auto',
						},
					},
				}}
				editorRef={(ref) => {
					attachmentRef.current = ref;
				}}
				toolbarClassName="rdw-storybook-toolbar-absolute"
				wrapperClassName="rdw-storybook-wrapper-margined"
				editorClassName="rdw-storybook-editor"
				onEditorStateChange={(e) => {
					setEditorValue(e);
					debouncedInput(e);
				}}
				handleReturn={(e, editorState) => {
					const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
					const text = editorState.getCurrentContent().getPlainText()?.trim();
					if (!!handleReturn) {
						// console.log("text :", text)
						// console.log("text :", value)
						// console.log("attachments.length :", attachments.length)
						handleReturn(e,value,text);
					}
				}}
				toolbarCustomButtons={
					isExternalAttachment
						? [
								<div
									className="rdw-option-wrapper attachment"
									aria-selected="false"
									title="Attachment"
								>
									<input
										type="file"
										id="data"
										onChange={(e) => onHandleFileAttachment(e.target.files)}
									/>
									<img src={Icons.Attachment} alt="Attachment" />
								</div>,
						  ]
						: []
				}
			/>
			{attachment.length > 0 && (
				<Box padding="10px" width="100%">
					{attachment.map((x: any) => (
						<Flex
							background="#f5f5f5"
							border="1px solid transparent"
							width="40%"
							justifyContent="space-between"
							alignItems="center"
							p="5px 10px"
							mb="10px"
							key={x.id}
						>
							<Text fontFamily="semibold" fontSize="14px">
								{x.name}
							</Text>
							<Popconfirm
								title="Are you sure to remove?"
								onConfirm={() => {
									onRemoveFileAttachment(x);
								}}
								okText="Yes"
								cancelText="No"
							>
								<MemoCloseIcon stroke="black" cursor="pointer" />
							</Popconfirm>
						</Flex>
					))}
				</Box>
			)}
		</Box>
	);
};
