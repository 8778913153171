import { Box, Text, Icon } from 'atoms';
import { FC } from 'react';

export type ConfirmEmailProps = {
	email?: string;
	withCode?: string | null;
	selectedPlan?: string | null;
};

export const ConfirmEmail: FC<ConfirmEmailProps> = ({ email }: ConfirmEmailProps) => {
	return (
		<Box px="16rem">
			<Box mb="2rem">
				{/* <Text
					fontSize="h4"
					lineHeight="h4"
					color="secondary.50"
					fontFamily="Archivo-Bold"
					mb="6rem"
					my="0.7rem"
				>
					My Coaching Place
				</Text> */}

				<Box mb="6rem">
					<Icon name="logo" height="7rem" mb="6rem" type={'text'} />
				</Box>
			</Box>
			<Text
				fontSize="23px"
				color="primary.50"
				fontFamily="Archivo-Bold"
				my="0.7rem"
				mb="2rem"
			>
				Check your email
			</Text>
			<Text as="p" fontSize="1.6rem" color="primary.150" mb="4rem">
				We’ve sent you a verification email at{' '}
				<Text as="span" fontFamily="semibold">
					{/* {email} */}
				</Text>
				.
			</Text>
			<Text as="p" fontSize="1.6rem" color="primary.600" mb="4rem">
				Didn’t get the email? Check your spam folder.
			</Text>
		</Box>
	);
};
