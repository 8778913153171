import { FC, useEffect } from 'react';
import { Spin } from 'antd';

import { Box, Flex } from 'atoms';
import useAuth from '../context/Authentication';

import { useLocationQuery } from 'hooks/useLocationQuery';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type LoginProps = {
	type: 'regular' | 'invite';
	userType?: any;
} & any;

export const LoginContinue: FC<LoginProps> = ({ type, userType, ..._props }: LoginProps) => {
	const { access_token } = useLocationQuery();
	const {
		actions: { outsetaLogin, switchProtal },
	} = useAuth();

	useEffect(() => {
		outsetaLogin(access_token, (res) => {
			if (
				res?.roles?.[0]?.name?.toLowerCase() === 'coach' ||
				res?.roles?.[0] === '649563cb8b03c56eba0a9111'
			) {
				switchProtal('coach');
				if (res?.user?.meta?.basicProfileCompleted) {
					window.location.href = '/coach/home';
				} else {
					window.location.href = '/coach/complete-profile';
				}
			} else if (res?.roles?.[0]?.name?.toLowerCase() === 'coachee') {
				switchProtal('coachee');
				if (res?.user?.meta?.basicProfileCompleted) {
					window.location.href = '/coachee/home';
				} else {
					window.location.href = '/coachee/complete-profile';
				}
			} else if (res?.roles?.[0]?.name?.toLowerCase() === 'admin') {
				switchProtal('admin');
				window.location.href = '/admin/home';
			}
		});
	}, []);

	return (
		<Flex
			mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
			minWidth={{ sm: '32rem', lg: '44rem' }}
			maxWidth="90%"
			height="calc(100vh - 100px)"
			alignItems={'center'}
			justifyContent="center"
		>
			<Box>
				<Spin indicator={antIcon} />{' '}
				<Box ml="1rem" as="span">
					Logging
				</Box>
			</Box>
		</Flex>
	);
};
