import { Select, SelectProps } from 'atoms/Select';
import { useField } from 'formik';
import { FC } from 'react';

export interface FormSelectProps extends SelectProps {
	name: string;
	onChangeCustom?: (data?: any) => void;
	minHeight?: string;
}

export const FormSelect: FC<FormSelectProps> = ({
	name,
	onChangeCustom,
	defaultValue,
	...props
}) => {
	const [field, meta] = useField(name);
	const error = (meta.touched && meta.error) || '';
	return (
		<Select
			{...field}
			{...props}
			error={error}
			onChange={(e) => {
				field.onChange({ target: { name, value: e } });
				onChangeCustom && onChangeCustom(e);
			}}
		/>
	);
};
