export const ClientRegister = `/sessions/register`;
export const CoachRegister = '/sessions/register';
export const Register = '/users/register';
export const Login = `/users/login`;
export const ChangePassword = `/user-session/user/`;
export const ResendVerificationToken = `/auth/users/verification-code`;
export const CodeVerify = `/user-session/verify-code`;

export const SchoolCodeVerify = `/schools`;
export const InvitationCodeVerify = `/connection/verify-invite`;
export const updateProfile = `/users`;
export const checkEmail = `/users/emails`;

export const getLanguages = `/languages`;
export const roles = `/roles`;

export const schools = `/schools`;
export const cohorts = `/cohorts`;

export const users = `/users`;
export const profileManagement = `/users/profile-management`;

export const plans = `/plans`;

export const payments = `/payments`;
export const getPaymentMethods = `/payments/users`;
export const addCard = `/payments/users/payment-methods`;
export const deleteCard = `/payments/payment-methods`;
export const credits = `/credits`;
export const changeCard = `/users/changeCard`;
export const subscription = `/usersubscription`;
export const subscriptionRetry = `/usersubscription/retry`;
export const userSubscription = `/usersubscription`;
export const userSubscriptionCharges = `/usersubscription/charges`;

export const upgradePlan = `/users/update-plan`;
export const extraSubscriptionCoupon = `/users/plans`;

export const twilioToken = `/messages/twilio-token`;
