import {
	Box,
	//Grid,
	Loader,
} from 'atoms';
import Navbar from 'organisms/Navbar';
import SideBar from 'organisms/Sidebar';
//import useAuth from 'pages/Auth/context/Authentication';
import { FC, Suspense, useState } from 'react';
//import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';

import { Layout as AntLayout } from 'antd';

const { Sider } = AntLayout;

export type LayoutProps = {
	children?: React.ReactNode;
	userType?: string;
	isFullscreen?: boolean;
};

export const Layout: FC<LayoutProps> = ({
	children,
	userType,
	isFullscreen,
	...props
}: LayoutProps) => {
	// const {
	// 	actions: { logout },
	// } = useAuth();
	//const navigate = useNavigate();
	const location = useLocation();

	const [collapsed, setCollapsed] = useState(false);
	return (
		<AntLayout>
			{!isFullscreen && (
				<Sider
					width={224}
					style={{ zIndex: 1 }}
					breakpoint="lg"
					collapsedWidth="0"
					onBreakpoint={(broken) => {
						console.log(broken);
					}}
					onCollapse={(collapsed, type) => {
						setCollapsed(!collapsed);
					}}
				>
					<SideBar type={userType} setCollapsed={setCollapsed} collapsed={collapsed} />
				</Sider>
			)}
			<AntLayout>
				<Suspense fallback={<Loader />}>
					{' '}
					<Box height={'100vh'} overflowY="scroll" id="layout" bg="white.25">
						{!isFullscreen && <Navbar userType={userType} />}
						<Box
							px={
								location.pathname === '/coach/sessions' ||
								location.pathname === '/coachee/sessions'
									? '0px'
									: { xs: '2rem', md: '4.8rem' }
							}
							py={
								location.pathname === '/coach/sessions' ||
								location.pathname === '/coachee/sessions' ||
								location.pathname === '/coach/manage-plans' ||
								location.pathname === '/coach/add-payment-method'
									? '0px'
									: '4.3rem'
							}
							//height={'100vh'}
							width={collapsed ? '100%' : 'auto'}
						>
							<Suspense fallback={<Loader />}>{children}</Suspense>{' '}
						</Box>{' '}
					</Box>
				</Suspense>
			</AntLayout>
		</AntLayout>
	);
};
