import React from 'react';
import { Flex } from 'atoms';

interface PageFooterProps {
	children?: React.ReactNode;
	isForm?: boolean;
	ml?: string;
}

export const PageFooter: React.FC<PageFooterProps> = ({ children, isForm, ml }: any) => {
	return (
		<Flex
			display={{ xs: isForm ? 'flex!important' : 'block!important', md: 'flex!important' }}
			bg="white.0"
			alignItems="center"
			flexDirection="row"
			gridGap="16px"
			position="fixed"
			borderTop="1px solid #E5E7EB"
			width={{ xs: '100%', md: 'calc(100% - 224px)' }}
			paddingY="30px"
			bottom="0"
			ml={{ xs: isForm ? '-21px' : '-40px', md: isForm ? ml || '-48px' : '-58px' }}
			px={{ xs: '20px', md: isForm ? '76px' : '48px' }}
			zIndex={11}
		>
			{children}
		</Flex>
	);
};
