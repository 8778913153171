import { Box, BoxProps } from 'atoms/Box';
import { forwardRef, ReactElement } from 'react';

export interface TextProps extends BoxProps {
	/**
	 * The dom element type of the text
	 */
	as?: any;
	/**
	 *
	 */
	children?: any;
	id?: string;
	ref?: any;
	title?: any;
	className?:any;
	onClick?: (e: any) => void;
}

export const Text = forwardRef<ReactElement | HTMLElement, TextProps>(
	({ as, children, ...rest }, ref) => {
		return (
			<Box as={as} ref={ref} {...rest}>
				{children}
			</Box>
		);
	}
);
Text.defaultProps = {
	as: 'div',
	children: '',
};
