import { Box, BoxProps, Flex, Text } from 'atoms';
import MemoArrowUpTray from 'assets/icons/ArrowUpTray';
import { handleRegularUpload } from 'hooks/useUploader';
import { randomString } from 'utils/helpers';
import { FormInput } from 'molecules/FormInput';

import React, { useState } from 'react';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';

import './upload.css';

const { Dragger } = Upload;

export interface AntdUploadAreaProps extends BoxProps {
	isMultiple?: boolean;
	onFileSelect?: (files: any) => void;
	accept?: string;
	label?: string;
	title?: string;
	Icon?: any;
	disabled?: boolean;
	defaultFileList?: any;
	callback?: (response: any) => void;
	showUrlField?: boolean;
	relativePath?: string;
	name?: string;
}

export const AntdUploadArea: React.FC<AntdUploadAreaProps> = ({
	isMultiple = false,
	title = '+ Upload',
	onFileSelect,
	accept = 'image/*,.pdf',
	label = 'file-upload',
	disabled = false,
	Icon,
	defaultFileList,
	callback,
	showUrlField = false,
	relativePath,
	name = 'file',
}: AntdUploadAreaProps) => {
	const [youtubeUrl, setYoutubeUrl] = useState('');

	const props: UploadProps = {
		name: name,
		multiple: false,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'uploading') {
				message.loading(`Uploading...`);
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log('Dropped files', e?.dataTransfer?.files);
		},
		customRequest({ data, file, onError, onProgress, onSuccess }) {
			message.loading(`Uploading files.`);

			// @ts-nocheck
			let filename = file?.name?.split('.')?.[0] || randomString(10);

			try {
				handleRegularUpload(
					file,
					{
						fileName: filename,
						relativePath: relativePath || `users/`,
						maxSize: 10,
					},
					(error: any, response: any) => {
						if (error) {
							//reject(error);
						} else {
							// resolve({
							// 	data: {
							// 		link: ASSET_ENDPOINT + response.key + `?ts=${Date.now()}`,
							// 	},
							// });
							if (typeof callback === 'function') {
								callback({
									...response,
									status: 'done',
									url: response?.Location,
									name: filename,
									uid: `-${defaultFileList?.length + 1}`,
									type: 1,
									size: 0,
								});
							}
						}
					}
				);

				message.destroy();
			} catch (e: any) {
				message.error(e);
			}
		},
		defaultFileList,
		showUploadList: {
			showDownloadIcon: true,
			downloadIcon: (
				<Text as="span" onClick={(e: any) => console.log(e, 'custom removeIcon event')}>
					View
				</Text>
			),
			showRemoveIcon: true,
			removeIcon: (
				<Text
					as="span"
					onClick={(e: any) => {
						e.stopPropagation();
						console.log(e, 'custom removeIcon event');
					}}
				>
					Remove
				</Text>
			),
		},
	};

	return (
		<Box>
			<Dragger {...props} accept={accept}>
				<MemoArrowUpTray width="24px" height="24px" stroke="#ffffff" />
				<Box fontSize="16px" mt="10px">
					<Text as="span" fontFamily="semibold" color="secondary.50">
						Select a file
					</Text>
					<Text as="span" color="primary.50">
						{' '}
						or drag and drop
					</Text>
				</Box>
				<Box fontSize="12px" color="grey.50" mt="4px" mb={showUrlField ? '4rem' : ''}>
					Maximum file size of 10MB
				</Box>
			</Dragger>
			{showUrlField && (
				<Flex
					zIndex={1}
					position="absolute"
					left="25%"
					bottom="0"
					justifyContent={'content'}
					width="50%"
					alignItems={'center'}
					id="ddd"
				>
					<FormInput
						value={youtubeUrl}
						name="url"
						placeholder="Youtube URL"
						onChange={(e) => {
							setYoutubeUrl(e.target.value);
							e.preventDefault();
						}}
						width="100%"
					/>
					<Box
						//as="span"
						ml="1rem"
						width="100px"
						cursor="pointer"
						onClick={() => {
							if (typeof callback === 'function') {
								let val: any = youtubeUrl;
								const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; //eslint-disable-line
								if (val && regex.test(val)) {
									callback({
										status: 'done',
										uid: `-${defaultFileList?.length + 1}`,
										url: youtubeUrl,
										type: 3,
									});
									setYoutubeUrl('');
								} else {
									message.error('Enter valid youtube url');
								}
							}
						}}
					>
						Add Url
					</Box>
				</Flex>
			)}
		</Box>
	);
};
