import { message as toast } from 'antd';

import axios, { AxiosRequestConfig } from 'axios';
import { AuthenticationException, PermissionException, ServerException } from 'exceptions';
import { clearCredentials } from 'pages/Auth/context/Authentication';
import { ApiInit, ApiResult } from './api.types';
import getResponseContent from './getResponseContent';
import makeRequestInit, { makeRequestInitt } from './makeRequestInit';
export interface ApiOptions extends ApiInit {
	keepBaseUri?: boolean;
	sendAccessToken?: boolean;
}

export default async function apiFetch<DataType = any>(
	relativeUri: string,
	options: ApiOptions = {}
) {
	try {
		const init = makeRequestInit(options);
		const { keepBaseUri } = options;
		const base = keepBaseUri
			? process.env.REACT_APP_API_HOST
			: `${process.env.REACT_APP_API_HOST}`;
		const response = await fetch(`${base}${relativeUri}`, init);

		if (response.status >= 400) {
			throw response;
		}

		const result: ApiResult<DataType> = {
			response,
			data: await getResponseContent<DataType>(response),
		};
		return result;
	} catch (response) {
		const content = await getResponseContent(response);
		let message;

		if (content && content.message) {
			({ message } = content);
		}

		if (response.status === 401) {
			// window.location.reload();
			throw new AuthenticationException(message);
		}

		if (response.status === 403) {
			throw new PermissionException(message);
		}

		throw new ServerException(content);
	}
}

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_HOST,
	timeout: 30000,
});

let loaderCount: number = 0;

export const api = async <DataType = any>(
	relativeUri: string,
	options: AxiosRequestConfig = {}
) => {
	const init = makeRequestInitt(options);
	try {
		if (init?.method === 'get' && loaderCount < 1) {
			toast.loading('Loading...', 1.5);
		}
		++loaderCount;
		const response: DataType = await axiosInstance.request({
			...init,
			url: relativeUri,
		});
		--loaderCount;
		return response;
	} catch (error: any) {
		const errorCode = error.response.status || error.response.statusCode;

		if (error.response && errorCode === 500) {
			try {
				const data = error.response.data;
				const message = data.message || data.errors.messages[0];

				if (typeof message === 'object') {
					throw new ServerException({
						statusCode: errorCode,
						message: message,
					});
				} else {
					throw new ServerException({ message: message });
				}
			} catch {
				const data = error.response.data;
				const message = data.message || data.errors.messages[0];
				throw new ServerException({
					message: message || 'Something went wrong.',
				});
			}
		}

		// refresh token stuff

		// if (error.response && errorCode === 403) {
		//   if (hydrate(AUTH_KEYS.AUTH_TOKEN_COOKIE, 'cookies') !== null) {
		//     removeCookie(AUTH_KEYS.AUTH_TOKEN_COOKIE);
		//     removeCookie(AUTH_KEYS.REFRESH_TOKEN_COOKIE);
		//     const {
		//       data: { data },
		//     } = await api('/user/refresh-token', {
		//       method: 'patch',
		//       headers: { authorization: `Bearer ${hydrate(USER_TOKEN, 'cookies')}` },
		//       data: {
		//         refresh_token: hydrate(AUTH_KEYS.REFRESH_TOKEN_COOKIE, 'cookies'),
		//       },
		//     });
		//     saveCookie(AUTH_TOKEN_COOKIE, data.auth_token);
		//     saveCookie(USER_TOKEN, data.access_token);
		//   }
		//   // throw new PermissionException(error?.response?.data?.error_message);
		// }

		if (error.response && [403, 401, 402, 429, 422, 404, 409, 400].includes(errorCode)) {
			let errorMessage =
				typeof error?.response?.data?.messages === 'object'
					? error?.response?.data?.messages?.[0]
					: error?.response?.data?.messages ||
					  error?.response?.data?.message ||
					  error?.response?.data?.messages?.[0] ||
					  error?.response?.data?.message?.[0]?.name ||
					  error?.response?.data?.error;

			let serverErrors = error.response.data.errors || error.response.data.message;
			if (errorCode === 422 && serverErrors && serverErrors.length > 0) {
				let errorKeys = '';
				errorKeys = serverErrors
					.map((item: any) => `${item?.field} - ${item?.error?.[0]}`)
					.join(' , ');

				errorMessage = errorKeys;
			}

			if (errorCode === 400 && serverErrors && serverErrors.length > 0) {
				let errorKeys = '';
				errorKeys = serverErrors
					.map((item: any) => {
						if (typeof item === 'object') {
							return Object.values(item)?.join('');
						} else {
							return item;
						}
					})
					.join(' , ');

				errorMessage = errorKeys;
			}

			//Logout.
			if (errorCode === 401) {
				// toast.error('Session expired. Please login again to continue', 1.5);
				if (relativeUri !== '/auth/users/login') {
					/**
					 * On login page don't show custom message and don't redirect same login page.
					 * Otherthan login url if 401 error occur, redirect to login page after showing errror.
					 */
					clearCredentials();
					window.location.href = '/login';
					errorMessage = 'Session expired. Please login again to continue';
				}
			}
			throw new ServerException({
				statusCode: error.response.status,
				message: errorMessage,
			});
		} else {
			throw new ServerException({
				message: error?.response?.data?.message,
			});
		}
	}
};

// https://github.com/kennethjiang/js-file-download
export const downloadFile = (data: any, filename: string, mime?: string, bom?: any) => {
	const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
	const blob = new Blob(blobData, {
		type: mime || 'application/octet-stream',
	});
	if (typeof window.navigator.msSaveBlob !== 'undefined') {
		// IE workaround for "HTML7007: One or more blob URLs were
		// revoked by closing the blob for which they were created.
		// These URLs will no longer resolve as the data backing
		// the URL has been freed."
		window.navigator.msSaveBlob(blob, filename);
	} else {
		const blobURL =
			window.URL && window.URL.createObjectURL
				? window.URL.createObjectURL(blob)
				: window.webkitURL.createObjectURL(blob);
		const tempLink = document.createElement('a');
		tempLink.style.display = 'none';
		tempLink.href = blobURL;
		tempLink.setAttribute('download', filename);

		// Safari thinks _blank anchor are pop ups. We only want to set _blank
		// target if the browser does not support the HTML5 download attribute.
		// This allows you to download files in desktop safari if pop up blocking
		// is enabled.
		if (typeof tempLink.download === 'undefined') {
			tempLink.setAttribute('target', '_blank');
		}

		document.body.appendChild(tempLink);
		tempLink.click();

		// Fixes "webkit blob resource error 1"
		setTimeout(function () {
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(blobURL);
		}, 200);
	}
};
