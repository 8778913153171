import axios from 'axios';
import { sha512 } from 'js-sha512';
import _ from 'lodash';
import LogRocket from 'logrocket';
import qs from 'qs';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { toast as message } from 'react-toastify';
import { io } from 'socket.io-client';
//import { useNavigate } from 'react-router';
//import { useLocationQuery } from 'hooks/useLocationQuery';
import { USER_STATUS } from 'typings/enums/users';
import { api } from 'utils/api/api';
import { ACCESS_TOKEN } from 'utils/api/getTokens';
import { buildQuery } from 'utils/buildQuery';
import { createAction, createContainer, createReducer } from 'utils/context';
import { removeCookie, saveCookie } from 'utils/cookie';
import { hydrate, persist } from 'utils/persist';
import { OUTSETA_ENDPOINT } from 'settings/constants';
import { APIRoutes } from '..';
// import { Client } from '@twilio/conversations';
const Chat = require('twilio-chat');
import { checkprofileCompleted } from 'helpers/common';

//import MQTT from '../../../libs/mqtt';
const socket = io(process.env.REACT_APP_SOCKET_HOST, {
	transportOptions: {
		polling: {
			extraHeaders: {
				Authorization: hydrate('token', 'localStorage') || undefined, // 'Bearer h93t4293t49jt34j9rferek...'
			},
		},
	},
}); //,{path:`/v1/api`}
interface SignupFormProps {
	outsetaAccessToken: string;
	userType: string;
	email: string;
	connectionId?: string;
	role: any;
	organizationVerticalCodeId?: string;
	status: number;
}

export function clearCredentials() {
	localStorage.removeItem('u_id');
	localStorage.removeItem('currentPortal');
	localStorage.removeItem('token');
	localStorage.removeItem('user');
	localStorage.removeItem('outsetaAccessToken');
	sessionStorage.removeItem('user');

	removeCookie(ACCESS_TOKEN);
}

export type AuthState = {
	authenticating: boolean;
	isLoggedIn: boolean;
	user?: any;
	userId?: any;
	currentPortal?: any;
	notifications: any;
	invitees: any;
	scriptions: any;
	card: any;
	userSubscription: any;
	paymentHistory: any;
	schools: any;
	cohorts: any;
	languages: any;
	roles: any;
	outsetaRef: any;
	preferenceInfo: any;
	roleIds: any;
	plans: any;
	paymentMethods: any;
	credits: any;
	subscriptionCredits: any;

	twilioClient: any;
	meetingNotification: any;
};

const initialState: AuthState = {
	authenticating: true,
	isLoggedIn: true,
	notifications: {
		list: [],
		unReadCount: 0,
		sessionCount: 0,
		messageCount: 0,
		connectionCount: 0,
		taskCount: 0,
	},
	invitees: null,
	scriptions: [],
	card: {},
	userSubscription: {},
	paymentHistory: [],
	schools: [],
	cohorts: [],
	languages: [
		{ label: 'English', _id: 'english' },
		{ label: 'Spanish', _id: 'spanish' },
	],
	roles: [],
	outsetaRef: {},
	preferenceInfo: {},
	roleIds: {},
	plans: [],
	paymentMethods: [],
	credits: {
		credits: [],
		meta: {},
	},
	subscriptionCredits: {},

	twilioClient: null,
	meetingNotification: null,
};

const actions = {
	login: createAction('LOGIN'),
	addSubscription: createAction('ADD_SUBSCRIPTION'),
	register: createAction('REGISTER'),
	resetAuth: createAction('RESET'),
	switchPortal: createAction('SWITCH'),
	updateUserData: createAction('UPDATE'),

	fetchSubscriptions: createAction('SUBSCRIPTIONS_LIST'),
	getPaymentMethods: createAction('PAYMENT_METHODS_LIST'),
	updateCardDetails: createAction('CARD_UPDATE'),
	userSubscription: createAction('USER_SUBSCRIPTION'),
	paymentHistory: createAction('PAYMENT_HISTORY'),
	schools: createAction('SCHOOLS'),
	cohorts: createAction('COHORTS'),
	getLanguages: createAction('LANGUAGES'),
	getRoles: createAction('ROLES'),
	fetchPlans: createAction('PLANS'),
	getPreferenceInfo: createAction('GET_PREFERENCE_INFO'),
	setRoleIds: createAction('SET_ROLE_IDS'),
	getCredits: createAction('CREDITS'),
	getCreditsOfUser: createAction('USER_CREDITS'),
	resetCredits: createAction('RESET_CREDITS'),
	subscriptionCredits: createAction('SUBSCRIPTION_CREDITS'),

	fetchNotifications: createAction('NOTIFICATIONS_LIST'),
	updateNotifications: createAction('UPDATE_NOTIFICATION'),
	setNotificationList: createAction('SET_NOTIFICATION_LIST'),
	setUnreadNotificationsCount: createAction('SET_UNREAD_NOTIFICATION_COUNT'),
	setMessageNotificationsCount: createAction('SET_MESSAGE_NOTIFICATION_COUNT'),
	setSessionNotificationsCount: createAction('SET_SESSIONS_NOTIFICATION_COUNT'),
	setTaskNotificationsCount: createAction('SET_TASK_NOTIFICATION_COUNT'),
	setConnectionNotificationsCount: createAction('SET_CONNECTION_NOTIFICATION_COUNT'),
	setMeetingNotification: createAction('SET_MEETING_NOTIFICATION'),

	setTwilioClient: createAction('SET_TWILIO_CLIENT'),
};

const authReducer = createReducer<AuthState>({
	[`${actions.login}`]: (state, { payload }) => ({
		...state,
		user: payload,
		userId: payload._id,
		isLoggedIn: true,
	}),
	[`${actions.addSubscription}`]: (state, { payload }) => {
		return {
			...state,
			user: payload,
			userId: payload._id,
			isLoggedIn: false,
		};
	},
	[`${actions.register}`]: (state, { payload }) => ({
		...state,
		user: payload,
		userId: payload?.user?._id,
		isLoggedIn: true,
	}),
	[`${actions.resetAuth}`]: (state) => ({
		...state,
		user: undefined,
		isLoggedIn: false,
		userId: undefined,
	}),
	[`${actions.updateUserData}`]: (state, { payload }) => ({
		...state,
		user: payload,
	}),
	[`${actions.switchPortal}`]: (state, { payload }) => {
		localStorage.setItem('currentPortal', payload);
		const token: any = hydrate('token', 'localStorage') || undefined;
		if (token) {
			saveCookie(ACCESS_TOKEN, token);
		}
		return {
			...state,
			currentPortal: payload,
		};
	},
	[`${actions.schools}`]: (state, { payload }) => ({
		...state,
		schools: payload,
	}),
	[`${actions.cohorts}`]: (state, { payload }) => ({
		...state,
		cohorts: payload,
	}),
	[`${actions.getLanguages}`]: (state, { payload }) => ({
		...state,
		languages: payload.languages,
	}),
	[`${actions.fetchSubscriptions}`]: (state, { payload }) => ({
		...state,
		scriptions: payload,
	}),
	[`${actions.fetchPlans}`]: (state, { payload }) => ({
		...state,
		plans: payload.plans || [],
	}),
	[`${actions.getPaymentMethods}`]: (state, { payload }) => ({
		...state,
		paymentMethods: payload?.paymentMethods || [],
	}),
	[`${actions.updateCardDetails}`]: (state, { payload }) => {
		let datas = state?.paymentMethods?.map((x: any) => {
			return payload?._id === x?._id
				? (x = {
						...x,
						...payload,
				  })
				: x;
		});

		return {
			...state,
			paymentMethods: datas,
		};
	},
	[`${actions.userSubscription}`]: (state, { payload }) => ({
		...state,
		userSubscription: payload,
	}),
	[`${actions.paymentHistory}`]: (state, { payload }) => ({
		...state,
		paymentHistory: payload,
	}),
	[`${actions.getPreferenceInfo}`]: (state, { payload }) => ({
		...state,
		preferenceInfo: payload,
		user: payload,
	}),
	[`${actions.getRoles}`]: (state, { payload }) => ({
		...state,
		roles: payload,
	}),
	[`${actions.setRoleIds}`]: (state, { payload }) => ({
		...state,
		roleIds: Object.fromEntries(payload.map((obj: any) => [obj?.name?.toLowerCase(), obj._id])),
	}),
	[`${actions.setMeetingNotification}`]: (state, { payload }) => ({
		...state,
		meetingNotification: payload,
	}),

	[`${actions.getCredits}`]: (state, { payload }) => {
		// ...state,
		// credits: {
		// 	credits: payload?.credits || [],
		// 	meta: payload?.meta,
		// },

		let datas;

		if (payload?.holdOldData) {
			datas = state?.credits?.credits?.concat(payload?.credits);
		} else {
			datas = payload?.credits;
		}

		return {
			...state,
			credits: {
				credits: _.uniqBy(datas, function (e: any) {
					return e._id;
				}),
				meta: payload?.meta,
			},
		};
	},
	[`${actions.getCreditsOfUser}`]: (state, { payload }) => ({
		...state,
		userCredits: [],
	}),
	[`${actions.resetCredits}`]: (state, { payload }) => ({
		...state,
		credits: {
			credits: [],
			meta: {},
		},
	}),
	[`${actions.subscriptionCredits}`]: (state, { payload }) => ({
		...state,
		subscriptionCredits: payload,
	}),

	[`${actions.fetchNotifications}`]: (state, { payload }) => {
		let { notifications } = state;
		notifications.list = payload;
		const unReadItem = payload.filter((x: any) => !x?.read);
		const connectionNotification = payload.filter(
			(x: any) => x?.notification?.module == 'Connections' && !x?.read
		);
		const taskNotification = payload.filter(
			(x: any) => x?.notification?.module == 'Tasks' && !x?.read
		);
		const sessionNotification = payload.filter(
			(x: any) => x?.notification?.module == 'Sessions' && !x?.read
		);
		notifications.unReadCount = unReadItem.length;
		notifications.taskCount = taskNotification.length;
		notifications.connectionCount = connectionNotification.length;
		notifications.sessionCount = sessionNotification.length;
		return {
			...state,
			notifications,
		};
	},
	[`${actions.setNotificationList}`]: (state, { payload }) => {
		const { notifications } = state;
		let isExist = notifications?.list.some((o: any) => o._id === payload._id);
		if (!isExist) {
			notifications.list.push(payload);
			notifications.list = _.orderBy(notifications.list, ['createdAt'], ['desc']);

			console.log('payload?.notification?.module: ', payload?.notification?.module);
			if (
				payload?.notification?.module == 'Messaging' &&
				!window.location.pathname.includes('/messages')
			) {
				notifications.messageCount = notifications.messageCount + 1;
				notifications.unReadCount = notifications.unReadCount + 1;
			}
			if (payload?.notification?.module == 'Tasks') {
				notifications.taskCount = notifications.taskCount + 1;
				notifications.unReadCount = notifications.unReadCount + 1;
			} else if (payload?.notification?.module == 'Connections') {
				notifications.connectionCount = notifications.connectionCount + 1;
				notifications.unReadCount = notifications.unReadCount + 1;
			} else if (payload?.notification?.module == 'Sessions') {
				notifications.sessionCount = notifications.sessionCount + 1;
				notifications.unReadCount = notifications.unReadCount + 1;
			}
		}
		return {
			...state,
			notifications,
		};
	},
	[`${actions.setUnreadNotificationsCount}`]: (state, { payload }) => {
		const { notifications } = state;
		notifications.unReadCount = payload;
		return {
			...state,
			notifications,
		};
	},
	// [`${actions.setMessageNotification}`]: (state, { payload }) => {
	// 	const { notifications } = state;
	// 	notifications.list = [payload, ...notifications.list];
	// 	notifications.messageCount += 1;
	// 	notifications.unReadCount += 1;
	// 	return {
	// 		...state,
	// 		notifications,
	// 	};
	// },
	[`${actions.setMessageNotificationsCount}`]: (state, { payload }) => {
		const { notifications } = state;
		notifications.messageCount = payload;
		return {
			...state,
			notifications,
		};
	},
	[`${actions.setSessionNotificationsCount}`]: (state, { payload }) => {
		const { notifications } = state;
		notifications.sessionCount = payload;
		return {
			...state,
			notifications,
		};
	},
	[`${actions.setTaskNotificationsCount}`]: (state, { payload }) => {
		const { notifications } = state;
		notifications.taskCount = payload;
		return {
			...state,
			notifications,
		};
	},
	[`${actions.setConnectionNotificationsCount}`]: (state, { payload }) => {
		const { notifications } = state;
		notifications.connectionCount = payload;
		return {
			...state,
			notifications,
		};
	},
	[`${actions.setTwilioClient}`]: (state, { payload }) => ({
		...state,
		twilioClient: payload,
	}),
});

function getOutseta() {
	if (window['Outseta']) {
		return window['Outseta'];
	} else {
		throw new Error('Outseta is missing, have you added the script to head?');
	}
}

export const {
	useContext: useAuth,
	Context: AuthContext,
	Provider: AuthProvider,
	TestProvider: TestAuthProvider,
} = createContainer(() => {
	//const [searchParams, setSearchParams] = useSearchParams();
	//const navigate = useNavigate();

	let url = new URL(window.location.href);

	let params = new URLSearchParams(url.search);

	let searchParams: any = Object.fromEntries(new URLSearchParams(params));

	//const searchParams: any = useLocationQuery();

	const [status, setStatus] = useState('init');
	// const [newMessageReceived, setNewMessageReceived] = useState(null);
	//const [user, setUser] = useState();

	console.log(status);

	// Save a reference to Outseta
	const outsetaRef = useRef(getOutseta());

	const initialValue = {
		...initialState,
		user: hydrate('user', 'localStorage') || undefined,
		isLoggedIn: !!localStorage.getItem('token'),
		currentPortal: localStorage.getItem('currentPortal'),
		userId: localStorage.getItem('u_id') || undefined,
		outsetaRef: outsetaRef,
	};

	const [state, dispatch] = useReducer(authReducer, initialValue);

	useEffect(() => {
		//console.log(searchParams);
		// Set up handling of user related events
		//handleOutsetaUserEvents(updateUser);

		// Get the access token from the callback url
		const accessToken: string = searchParams?.access_token;

		if (accessToken) {
			localStorage.setItem('outsetaAccessToken', accessToken);
		}

		if (accessToken && searchParams?.via_google && searchParams?.page !== 'login') {
			// If there is an acccess token present
			// pass it along to Outseta
			outsetaRef.current.setAccessToken(accessToken);

			// and clean up
			//setSearchParams({});
			if (outsetaRef.current.getAccessToken()) {
				// Outseta initalized with an authenticated user.
				updateUser();
			} else {
				// Outseta initalized without authenticated user.
				setStatus('ready');
			}
		}

		return () => {
			// Clean up user related event subscriptions
			handleOutsetaUserEvents(() => {});
		};
	}, [searchParams]);

	const updateUser = async () => {
		// Fetch the current user data from outseta
		const outsetaUser = await outsetaRef.current.getUser();

		if (outsetaUser?.Email) {
			getRoles((roles) => {
				getRoleId(roles, searchParams?.type, (roleId) => {
					register(
						{
							...searchParams,
							...outsetaUser,
							userType: searchParams?.type,
							role: roleId,
						},
						(res) => {
							const coach =
								res?.user?.roles?.[0]?.name?.toLowerCase() === 'coach' ||
								res?.roles?.[0]?.name?.toLowerCase() === 'coach';

							if (coach) {
								switchProtal('coach');
								setTimeout(() => {
									if (res?.user?.meta?.basicProfileCompleted) {
										window.location.href = '/coach/home';
									} else {
										window.location.href = '/coach/complete-profile';
									}
								});
							} else {
								switchProtal('coachee');

								setTimeout(() => {
									if (res?.user?.meta?.basicProfileCompleted) {
										window.location.href = '/coachee/home';
									} else {
										window.location.href = '/coachee/complete-profile';
									}
								});
							}
						}
					);

					// Update user state
					//setUser(outsetaUser);
					// Make sure status = ready
					setStatus('ready');
				});
			});
		}
	};

	const handleOutsetaUserEvents = (onEvent: any) => {
		// Subscribe to user related events
		// with onEvent function
		const outseta = outsetaRef.current;
		console.log(outseta);
		//outseta.on('subscription.update', onEvent);
		//outseta.on('profile.update', onEvent);
		//outseta.on('account.update', onEvent);
		// outseta.on('signup', () => {
		// 	console.log('Enter Signup');
		// });
	};

	const outsetaLogout = () => {
		// Unset access token
		outsetaRef.current.setAccessToken('');
		// and remove user state
		//setUser(null);
	};

	// useEffect(() => {
	// 	MQTT.subscribe(`notifications/${state.userId}`);
	// 	setTimeout(() => {
	// 		if (state?.isLoggedIn) {
	// 			/**
	// 			 * Fetch notfication only on LoggedIn state
	// 			 */
	// 			//fetchNotifications();
	// 		}
	// 	}, 1000);
	// }, [state.userId]);

	// useEffect(() => {
	// 	MQTT.on('message', (topic: string, messageData: any) => {
	// 		if (topic === `notifications/${state.userId}`) {
	// 			//dispatch(actions.setNotifications(messageData));
	// 		}
	// 	});
	// }, []);

	const notificationEventListener = (messageData: any) => {
		console.log('Notification Data: ', messageData);
		dispatch(actions.setNotificationList(messageData));
	};
	const meetingEventListener = (messageData: any) => {
		console.log('Meeting Data: ', messageData);
		dispatch(actions.setMeetingNotification(messageData));
	};
	const deactivateListener = (messageData: any) => {
		console.log('User Message Data: ', messageData);
		localStorage.clear();
		window.location.href = '/login';
	};

	useEffect(() => {
		if (state.userId) {
			fetchNotifications();
			getTwilioClient(state?.user?._id);
			socket.on(`notification`, notificationEventListener);
			socket.on(`meeting`, meetingEventListener);
			socket.on(`user-messages`, deactivateListener);
		}
		return () => {
			socket.off(`notification`, notificationEventListener);
			socket.off(`meeting`, meetingEventListener);
			socket.off(`user-messages`, deactivateListener);
		};
	}, [state.userId]);

	const sendMeetingResponse = (userId: string, response: string) => {
		const payload = {
			userId: userId,
			msg: response,
		};
		socket.emit('meeting', payload);
	};

	useEffect(() => {
		if (state.twilioClient) {
			state?.twilioClient.on('tokenAboutToExpire', async () => {
				const token = await getTwilioToken(state?.user?._id);
				state?.twilioClient.updateToken(token);
			});
			state?.twilioClient.on('tokenExpired', async () => {
				const token = await getTwilioToken(state?.user?._id);
				state?.twilioClient.updateToken(token);
			});
			// state?.twilioClient.on('messageAdded', (message: any) => {
			// 	setNewMessageReceived(message);
			// });
			return () => {
				state?.twilioClient.on('tokenAboutToExpire', () => {});
				state?.twilioClient.on('tokenExpired', () => {});
				// state?.twilioClient.on('messageAdded', () => {});
			};
		}
	}, [state.twilioClient]);

	// useEffect(() => {
	// 	if (
	// 		newMessageReceived &&
	// 		!window.location.pathname.includes('/messages') &&
	// 		newMessageReceived?.author !== state?.user?._id
	// 	) {
	// 		const payload = {
	// 			notification: {
	// 				module: 'Messages',
	// 			},
	// 			read: false,
	// 			msgContent: 'New Message Received',
	// 			createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ss'), //moment(newMessageReceived?.dateCreated).format() ||
	// 		};

	// 		dispatch(actions.setMessageNotification(payload));
	// 	}
	// }, [newMessageReceived]);

	function setUserData(data: any) {
		const { user, accessToken }: any = data;
		//delete data.data.user.accessToken;
		persist('token', accessToken, 'localStorage');
		persist('u_id', user?._id, 'sessionStorage');
		persist('u_id', user?._id, 'localStorage');
		persist('user', user, 'localStorage');
		// if (
		// 	!data.data.user.roles.coach ||
		// 	(data.data.user.roles.coach && data.data.user?.stripeCustomerId) ||
		// 	data.data.user?.isSupervisor === true
		// ) {
		// 	dispatch(actions.login(data.data.user));
		// }
		//MQTT.subscribe(`notifications/${data.data.user._id}`);
	}

	const login = useCallback(async (values, callback?: (res: any) => void) => {
		let password = '';
		if (values.password) {
			password = sha512(values.password);
		}
		try {
			const { data } = await api(APIRoutes.Login, {
				method: 'post',
				data: { ...values, password },
			});
			const { status, isAdmin } = data.data.user;
			//Even if success login, If Status = 0 (email not verified), dont save or update store/presist on storage.
			if (isAdmin) {
				persist('tempAdminUser', values?.email, 'sessionStorage');
			} else if (status !== 0) {
				setUserData(data);
			}
			if (typeof callback === 'function') {
				callback(data.data.user);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const outsetaLogin = useCallback(async (accessToken, callback?: (res: any) => void) => {
		try {
			const { data } = await api(APIRoutes.Login, {
				method: 'post',
				data: { outsetaAccessToken: accessToken },
			});
			//const { isAdmin } = data.data.user;
			//Even if success login, If Status = 0 (email not verified), dont save or update store/presist on storage.
			// if (isAdmin) {
			// 	persist('tempAdminUser', values?.email, 'sessionStorage');
			// } else if (status !== 0) {
			// 	setUserData(data);
			// }

			let user = {
				...data,
				...data.user,
			};

			setUserData(user);
			dispatch(actions.updateUserData(user));

			if (typeof callback === 'function') {
				callback(user);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const outsetaRegister = useCallback(
		async (values: any, callback?: (res: any, error?: any) => void) => {
			let data = axios
				.post(`${OUTSETA_ENDPOINT}crm/people/resetPassword/${values?.confirmationToken}`, {
					NewPassword: values?.password,
				})
				.then(function (response) {
					getOutsetaAccessToken({ ...response.data, ...values }, callback);
				})
				.catch(function (error) {
					if (error?.response?.status === 404) message.error(error?.response?.statusText);
				});

			console.log(data);
		},
		[]
	);

	const getOutsetaAccessToken = useCallback(
		async (values: any, callback?: (res: any) => void) => {
			let newData = {
				username: values?.Email,
				password: values?.password,
			};

			const options: any = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
				data: qs.stringify(newData),
				url: `${OUTSETA_ENDPOINT}tokens`,
			};
			axios(options)
				.then(function (response) {
					if (values?.method === 'login') {
						window.location.href = `/login/continue?access_token=${response?.data?.access_token}`;
					} else register({ ...values, ...response?.data }, callback);
				})
				.catch(function (error) {
					console.log(error?.Message);
					message.error(error?.Message);
				});
		},
		[]
	);

	const outsetaForgotPassword = useCallback(
		async (values: any, callback?: (res: any, error?: any) => void) => {
			let data = axios
				.post(
					`${OUTSETA_ENDPOINT}crm/people/forgotPassword?parentUrl=${process.env.REACT_APP_ENV_HOST}/reset-password`,
					values
				)
				.then(function (response) {
					callback(response.data);
					//getOutsetaAccessToken({ ...response.data, ...values }, callback);
				})
				.catch(function (error) {
					//if (error?.response?.status === 404) message.error(error?.response?.statusText);
				});
		},
		[]
	);

	const outsetaResetPassword = useCallback(
		async (values: any, callback?: (res: any, error?: any) => void) => {
			let data = axios
				.post(
					`${OUTSETA_ENDPOINT}crm/people/resetPassword/${
						values?.resetToken
					}?parentUrl=${encodeURIComponent(window.location.href)}`,
					values
				)
				.then(function (response) {
					getOutsetaAccessToken(
						{
							...response.data,
							...values,
							password: values?.NewPassword,
							method: 'login',
						},
						callback
					);
					//callback(response.data);
				})
				.catch(function (error) {
					console.log('error?.response', error?.response);
					if (error?.response?.status === 405)
						message.error(error?.response?.data?.Message);
					else
						message.error(
							error?.response?.data?.Message || error?.response?.statusText
						);
				});
		},
		[]
	);

	const register = useCallback(async (values: any, callback?: (res: any) => void) => {
		//const { userType, location, organizationName, organizationRole } = values;
		try {
			//Follwing scenarios
			// 1) if registerType is "regular" change the api to "/v1/coaches"
			// 2) if registerType is "invite", but userType is coach/supervisor, use "/v1/coaches"
			const endpoint = APIRoutes.Register;

			let newValue: SignupFormProps = {
				outsetaAccessToken: values?.access_token,
				userType: values?.type,
				email: values?.Email,
				role: values?.role,
				status: USER_STATUS.VERIFIED,
			};

			if (values?.connection) {
				newValue.connectionId = values.connection;
			}

			if (values?.organizationVerticalCode) {
				newValue.organizationVerticalCodeId = values.organizationVerticalCode;
			}

			// API call
			const { data } = await api(endpoint, {
				method: 'post',
				data: newValue,
			});

			setUserData(data);
			persist('user', data.user, 'localStorage');
			persist('outsetaAccessToken', values?.access_token, 'localStorage');
			dispatch(actions.updateUserData(data.user));

			if (typeof callback === 'function') {
				setTimeout(() => callback({ ...data, ...values }));
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const fetchNotifications = useCallback(async (callback?: (res: any) => void) => {
		try {
			// const queryParams = {
			// 	page: 1,
			// 	limit: 50,
			// };
			const { data } = await api(`/notifications`, {
				//?${buildQuery(queryParams)}
				method: 'get',
			});
			const filterData = data?.notifications.filter(
				(not: any) => not?.notification?.notificationType === 'in-app'
			);
			dispatch(actions.fetchNotifications(filterData));
			if (typeof callback === 'function') {
				callback(data.data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
		}
	}, []);

	const updateNotificationCount = useCallback(
		async (count: number, field: string, callback?: (res: any) => void) => {
			if (field === 'unReadCount') {
				dispatch(actions.setUnreadNotificationsCount(count));
			} else if (field === 'sessionCount') {
				dispatch(actions.setSessionNotificationsCount(count));

				const sessionIds = state.notifications?.list
					?.filter((x: any) => x?.notification?.module == 'Sessions')
					?.map((x: any) => x?._id);
				updateNotifications({ read: sessionIds });
			} else if (field === 'messageCount') {
				dispatch(actions.setMessageNotificationsCount(count));

				const msgIds = state.notifications?.list
					?.filter((x: any) => x?.notification?.module == 'Messaging')
					?.map((x: any) => x?._id);
				updateNotifications({ read: msgIds });
			} else if (field === 'connectionCount') {
				dispatch(actions.setConnectionNotificationsCount(count));

				const connectionIds = state.notifications?.list
					?.filter((x: any) => x?.notification?.module == 'Connections')
					?.map((x: any) => x?._id);
				updateNotifications({ read: connectionIds });
			} else if (field === 'taskCount') {
				dispatch(actions.setTaskNotificationsCount(count));

				const taskIds = state.notifications?.list
					?.filter((x: any) => x?.notification?.module == 'Tasks')
					?.map((x: any) => x?._id);
				updateNotifications({ read: taskIds });
			}
		},
		[]
	);

	const updateNotifications = useCallback(async (payload: any, callback?: (res: any) => void) => {
		try {
			const { data } = await api(`/notifications/read`, {
				method: 'post',
				data: payload,
			});
			fetchNotifications();
			if (typeof callback === 'function') {
				callback(data.notifications);
			}
		} catch (error) {}
	}, []);

	const fetchMe = useCallback(async (callback?: (res: any) => void) => {
		try {
			const { data } = await api(
				`/users/${localStorage.getItem(
					'u_id'
				)}?fields=_id,appNotifications,emailNotifications,isSupervisor,status,email,firstName,lastName,roles,profiles,limnuUserId,limnuUserToken,googleIdToken,googleClientId,gender,tutorials,renewalDate,userSubscriptionStatus&expand=roles.coach roles.client`,
				{
					method: 'get',
				}
			);
			persist('user', data.data.user, 'localStorage');

			dispatch(actions.updateUserData(data.data.user));

			if (typeof callback === 'function') {
				callback(data.data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
		}
	}, []);

	const changePassword = useCallback(async (values, callback?: () => void) => {
		let dd = localStorage.getItem('outsetaAccessToken');

		outsetaRef.current.setAccessToken(dd);
		axios.defaults.headers.put['Authorization'] = `Bearer ${localStorage.getItem(
			'outsetaAccessToken'
		)}`;
		let accessToken = outsetaRef.current.getAccessToken();

		if (accessToken) {
			axios
				.put(`${OUTSETA_ENDPOINT}profile/password`, {
					ExistingPassword: values?.oldPassword,
					NewPassword: values?.newPassword,
				})
				.then(function (response) {
					if (typeof callback === 'function') {
						callback();
					}
				})
				.catch(function (error) {
					console.log(error.response);
					if (error?.response?.status === 404)
						message.error(
							error?.response?.data?.Message || error?.response?.statusText
						);
					if (error?.response?.status === 400)
						message.error(
							error?.response?.data?.EntityValidationErrors?.[0]
								?.ValidationErrors?.[0]?.ErrorMessage || 'Invalid data'
						);
					if (error?.response?.status === 401) {
						clearCredentials();
						window.location.href = '/login';
						message.error('Session expired. Please login again to continue');
					}
				});
		}
	}, []);

	const changeEmail = useCallback(async (values, callback?: () => void) => {
		let dd = localStorage.getItem('outsetaAccessToken');

		outsetaRef.current.setAccessToken(dd);
		axios.defaults.headers.put['Authorization'] = `Bearer ${localStorage.getItem(
			'outsetaAccessToken'
		)}`;
		let accessToken = outsetaRef.current.getAccessToken();

		if (accessToken) {
			axios
				.put(`${OUTSETA_ENDPOINT}profile`, {
					Email: values?.email,
				})
				.then(function (response) {
					if (typeof callback === 'function') {
						callback();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	}, []);

	const logout = useCallback(async () => {
		clearCredentials();
		dispatch(actions.resetAuth());
	}, []);

	const switchProtal = useCallback(async (currentPortal) => {
		dispatch(actions.switchPortal(currentPortal));
		//MQTT.subscribe(`notifications/${state.userId}`);
		setTimeout(() => {
			//fetchNotifications();
		}, 1000);
		if (process.env.REACT_APP_ENV === 'production') {
			LogRocket.identify(state.userId, {
				portal: currentPortal,
			});
		}
	}, []);

	const resendConfirmEmail = useCallback(async (values, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.ResendVerificationToken, {
				method: 'post',
				data: { ...values },
			});
			message.success('Email resent');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const schoolCodeVerify = useCallback(async (values, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.schools}/add-coach`, {
				method: 'patch',
				data: { ...values },
			});
			message.success('Code Verified');
			if (typeof callback === 'function') {
				callback(data.school);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const invitationTokenVerify = useCallback(
		async (values, connectionId: string, callback?: (data: any) => void) => {
			try {
				const { data } = await api(`${APIRoutes.InvitationCodeVerify}/${connectionId}`, {
					method: 'put',
					data: { ...values },
				});
				message.success('Invitation Token Verified');
				if (typeof callback === 'function') {
					callback(data);
				}
			} catch (error) {
				if (error.status === 401) {
					await logout();
				}
				message.error(error.message);
			}
		},
		[]
	);

	const checkEmailAlreadyExists = useCallback(
		async (values, page = 'about', callback?: (data: any) => void) => {
			try {
				const { data } = await api(`${APIRoutes.checkEmail}/${values?.email}`, {
					method: 'get',
				});

				if (typeof callback === 'function') {
					if (page === 'about')
						if (data?.user) {
							message.error(`Email already exists`);
						} else {
							callback(data?.user);
						}
					else if (page === 'forgot')
						if (!data?.user) {
							message.error(`Email not exists`);
						} else if (data?.user?.status === 0) {
							message.error(`Please verify your account`);
						} else {
							callback(data?.user);
						}
				}
			} catch (error) {
				if (error.status === 401) {
					await logout();
				}
				message.error(error.message);
			}
		},
		[]
	);

	const getProfile = useCallback(async (callback?: (res: any) => void) => {
		try {
			let id = state?.user?._id || state?.userId;

			const { data } = await api(`${APIRoutes.users}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getPreferenceInfo(data.user));

			if (typeof callback === 'function') {
				callback(data.user);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getProfileManagement = useCallback(async (callback?: (res: any) => void) => {
		try {
			let id = state?.user?._id || state?.userId;

			const { data } = await api(`${APIRoutes.users}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getPreferenceInfo(data.data));

			if (typeof callback === 'function') {
				callback(data.data);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getOtherProfile = useCallback(async (id: string, callback?: (res: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.users}/${id}`, {
				method: 'get',
			});

			if (typeof callback === 'function') {
				callback(data.user);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const updateProfileManagement = useCallback(
		async (profile: any, callback?: (res: any) => void) => {
			try {
				let id = state?.user?._id || state?.userId;

				const { data } = await api(`${APIRoutes.users}/${id}`, {
					method: 'put',
					data: profile,
				});
				message.success('Profile preferences updated successfully');

				let OldData: any = hydrate('user', 'localStorage');
				let newData = { ...OldData, ...data.data, user: { ...data.data } };

				persist('user', newData, 'localStorage');
				dispatch(actions.updateUserData(newData));
				if (typeof callback === 'function') {
					callback(data.data);
				}
			} catch (error) {
				message.error(error.message);
			}
		},
		[]
	);

	const CodeVerify = useCallback(async (values: any, callback?: (res: any) => void) => {
		try {
			let id = state?.user?.user?._id || state?.user?._id || state?.userId;

			const { data } = await api(`${APIRoutes.CodeVerify}/${id}`, {
				method: 'put',
				data: values,
			});
			message.success('Code verified successfully');

			if (typeof callback === 'function') {
				callback(data.data);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getSchools = useCallback(async (callback?: (res: any) => void) => {
		try {
			const { data } = await api(APIRoutes.schools, {
				method: 'get',
			});
			dispatch(actions.schools(data.data.schools));
			if (typeof callback === 'function') {
				callback(data.data);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getCohorts = useCallback(async (callback?: (res: any) => void) => {
		try {
			const { data } = await api(APIRoutes.cohorts, {
				method: 'get',
			});
			dispatch(actions.cohorts(data.data.cohorts));
			if (typeof callback === 'function') {
				callback(data.data);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getRoles = useCallback(async (callback?: (res: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.roles}?all=true`, {
				method: 'get',
			});
			dispatch(actions.getRoles(data.roles));
			dispatch(actions.setRoleIds(data.roles));
			if (typeof callback === 'function') {
				callback(data.roles);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getLanguages = useCallback(async (callback?: (res: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.getLanguages}?all=true`, {
				method: 'get',
			});
			dispatch(actions.getLanguages(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const updateProfile = useCallback(
		async (profile: any, callback?: (res: any) => void, messageText?: string) => {
			try {
				let id = state?.user?.user?._id || state?.user?._id || profile?._id;
				let userType =
					state?.user?.user?.role?.name || state?.user?.role?.name || profile?.userType;

				delete profile.user;

				if (profile?.hoursCoached) {
					profile = {
						...profile,
						preference: {
							coachee: state?.user?.user?.preference?.coachee,
							coach: {
								...profile.preference?.coach,
								hoursCoached: parseInt(profile?.hoursCoached),
							},
						},
					};

					delete profile.hoursCoached;
				}

				/* Condition based profile completed flag */
				// if (!state?.user?.meta?.profileCompleted) {
				// 	let profileCompleteStatus = await checkprofileCompleted(
				// 		profile,
				// 		state?.user?.school
				// 	);
				// 	profile.meta.profileCompleted = profileCompleteStatus;
				// }

				const { data } = await api(`${APIRoutes.updateProfile}/${id}`, {
					method: 'put',
					data: {
						...profile,
						userType,
					},
				});
				let OldData: any = hydrate('user', 'localStorage');
				let newData = { ...OldData, ...data.user };
				persist('user', newData, 'localStorage');

				dispatch(actions.updateUserData(newData));

				message.success(messageText || 'Profile updated successfully.');

				if (typeof callback === 'function') {
					callback(data);
				}
			} catch (error) {
				if (error.status === 401) {
					await logout();
				}
				message.error(error.message);
			}
		},
		[]
	);

	const deleteAccount = useCallback(async (reason: any, callback?: (res: any) => void) => {
		try {
			const { data } = await api(APIRoutes.updateProfile, {
				method: 'delete',
				data: {
					...reason,
				},
			});

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const getRoleId = useCallback(
		async (roles: any, type: string, callback?: (res: any) => void) => {
			let findId: string = roles?.filter((x: any) => x?.name?.toLowerCase() === type)?.[0]
				?._id;
			if (typeof callback === 'function') {
				callback(findId);
			}
		},
		[]
	);

	const fetchPlans = useCallback(async (callback?: (res: any) => void) => {
		try {
			const { data } = await api(APIRoutes.plans, {
				method: 'get',
			});
			dispatch(actions.fetchPlans(data));
			if (typeof callback === 'function') {
				callback(data.plans);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getPlan = useCallback(async (id: string, callback?: (res: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.plans}/${id}`, {
				method: 'get',
			});
			if (typeof callback === 'function') {
				callback(data.plan);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const unSubscribePlan = useCallback(
		async (userId: string, values, callback?: (data: any) => void) => {
			try {
				const { data } = await api(`${APIRoutes.users}/${userId}/unsubscribe-plan`, {
					method: 'patch',
					data: { ...values },
				});
				if (data) {
					message.success('Unsubscibed successfully');
					// subscription(
					// 	{
					// 		subscription: values?.subscription,
					// 		user: values?.user,
					// 		recurringType: values?.recurringType,
					// 	},
					// 	callback
					// );
					if (typeof callback === 'function') {
						callback(data);
					}
				}
			} catch (error) {
				if (error.status === 401) {
					await logout();
				}
				message.error(error.message);
			}
		},
		[]
	);

	const addPayment = useCallback(async (values, callback?: (data: any, error?: any) => void) => {
		try {
			const { data } = await api(APIRoutes.payments, {
				method: 'post',
				data: { ...values },
			});
			if (data) {
				//message.success('Payment successful');
				if (typeof callback === 'function') {
					callback(data?.payment);
				}
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			callback('', error);
			message.error(error.message);
		}
	}, []);

	const upgradePlanPayment = useCallback(async (values, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.upgradePlan, {
				method: 'patch',
				data: { ...values },
			});
			if (data) {
				message.success('Plan changed successfully');
				if (typeof callback === 'function') {
					callback(data?.payment);
				}
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const addCard = useCallback(async (values, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.addCard, {
				method: 'post',
				data: { ...values },
			});
			if (data) {
				message.success('Card added successfully');
				if (typeof callback === 'function') {
					callback(data?.card);
				}
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const changeCard = useCallback(async (values, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.changeCard, {
				method: 'patch',
				data: { ...values },
			});
			message.success('Card changed successfully');
			if (typeof callback === 'function') {
				// subscriptionRetry({
				// 	userSubscriptionId: values?.userSubscriptionId,
				// });
				callback(data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const deleteCard = useCallback(async (cardId, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.deleteCard}/${cardId}`, {
				method: 'delete',
			});
			message.success('Card Deleted');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const getPaymentMethods = useCallback(
		async (customerId: string, callback?: (res: any) => void) => {
			try {
				const { data } = await api(
					`${APIRoutes.getPaymentMethods}/${customerId}/payment-methods`,
					{
						method: 'get',
					}
				);
				dispatch(actions.getPaymentMethods(data));
				if (typeof callback === 'function') {
					callback(data.data);
				}
			} catch (error) {
				message.error(error.message);
			}
		},
		[]
	);

	const getCredits = useCallback(async (queryParams: any, callback?: (res: any) => void) => {
		try {
			if (Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			if (parseInt(queryParams?.page) === 1) dispatch(actions.resetCredits());
			const { data } = await api(`${APIRoutes.credits}?${buildQuery(queryParams)}`, {
				method: 'get',
			});
			dispatch(actions.getCredits({ ...data, holdOldData: queryParams?.holdOldData }));
			if (typeof callback === 'function') {
				callback(data.credits);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getCreditsOfUser = useCallback(async (callback?: (res: any) => void) => {
		let id = state?.user?.user?._id || state?.user?._id;
		try {
			const { data } = await api(`${APIRoutes.credits}/users/${id}`, {
				method: 'get',
			});
			dispatch(actions.getCreditsOfUser({ ...data }));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const getCredit = useCallback(async (creditId: any, callback?: (res: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.credits}/${creditId}`, {
				method: 'get',
			});
			if (typeof callback === 'function') {
				callback(data.credit);
			}
		} catch (error) {
			message.error(error.message);
		}
	}, []);

	const setAsDefault = useCallback(
		async (customerId: string, values: any, callback?: (data: any) => void) => {
			try {
				const { data } = await api(
					`${APIRoutes.getPaymentMethods}/${customerId}/default-source`,
					{
						method: 'patch',
						data: { ...values },
					}
				);
				message.success('Card changed successfully');
				dispatch(actions.updateCardDetails(data));
				if (typeof callback === 'function') {
					callback(data);
				}
			} catch (error) {
				if (error.status === 401) {
					await logout();
				}
				message.error(error.message);
			}
		},
		[]
	);

	const applyCoupon = useCallback(async (values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.payments}/apply-coupon`, {
				method: 'patch',
				data: { ...values },
			});
			message.success('Coupon applied successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const applyExtraCoupon = useCallback(async (values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(
				`${APIRoutes.extraSubscriptionCoupon}/${values?.planId}/add-coupon`,
				{
					method: 'patch',
					data: values,
				}
			);

			message.success('Coupon applied successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			if (error.status === 401) {
				await logout();
			}
			message.error(error.message);
		}
	}, []);

	const getSubscriptionCredits = useCallback(
		async (userId: string, callback?: (res: any) => void) => {
			try {
				const { data } = await api(`${APIRoutes.users}/${userId}/subscription-credits`, {
					method: 'get',
				});
				dispatch(actions.subscriptionCredits({ ...data }));
			} catch (error) {
				message.error(error.message);
			}
		},
		[]
	);

	const getTwilioToken = async (identity: string) => {
		try {
			if (identity) {
				const { data } = await api(`${APIRoutes.twilioToken}?email=${identity}`, {
					method: 'get',
				});
				return data?.twilio?.token;
			}

			// if (identity) {
			// 	const response = await fetch(`http://localhost:5000/auth/user/${identity}`)
			// 	const responseJson = await response.json()
			// 	return responseJson.token
			// }
		} catch (error) {
			message.error(error.message);
		}
	};
	const getTwilioClient = async (userId: string) => {
		try {
			const token = await getTwilioToken(userId);
			// const client = new Client(token);
			const client = await Chat.Client.create(token);
			dispatch(actions.setTwilioClient(client));
		} catch {
			message.error(`Unable to get token, please reload this page`);
		}
	};

	const postMessageCount = async (payload: any) => {
		try {
			const { data } = await api(`/messages/counts`, {
				method: 'post',
				data: { ...payload },
			});
		} catch (error) {
			message.error(error.message);
		}
	};

	return {
		state,
		actions: {
			logout,
			resendConfirmEmail,
			switchProtal,
			login,
			register,
			getProfile,
			updateProfile,
			fetchMe,
			fetchPlans,
			changePassword,
			deleteAccount,
			getSchools,
			getCohorts,
			outsetaLogout,
			outsetaRegister,
			outsetaLogin,
			getLanguages,
			getProfileManagement,
			updateProfileManagement,
			CodeVerify,
			getOtherProfile,
			schoolCodeVerify,
			invitationTokenVerify,
			changeEmail,
			getRoles,
			getRoleId,
			addPayment,
			addCard,
			changeCard,
			deleteCard,
			getPaymentMethods,
			setAsDefault,
			getCredits,
			getCreditsOfUser,
			getCredit,
			getPlan,
			applyCoupon,
			getSubscriptionCredits,
			unSubscribePlan,
			upgradePlanPayment,
			updateNotificationCount,
			updateNotifications,
			checkEmailAlreadyExists,
			postMessageCount,
			sendMeetingResponse,
			applyExtraCoupon,
			outsetaForgotPassword,
			outsetaResetPassword,
		},
	};
});

export default useAuth;
