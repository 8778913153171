import { createContext, useEffect, useState } from 'react';

import { Text, Box, Flex, Icon } from 'atoms';
import ClientWelcome from './ClientWelcome';
import ClientGetCode from './ClientWelcome/ClientGetCode';
import { useLocationQuery } from 'hooks/useLocationQuery';

import useOrganizations from 'pages/Organizations/context/organizations';

export const FormContext = createContext({});

type formValuesType = {
	userType: number;
	type: string;
};

function ClientRegisterPage({ userType, type }: formValuesType) {
	const searchParams: any = useLocationQuery();

	const {
		actions: { verifyCode },
	} = useOrganizations();

	const [activeStepIndex, setActiveStepIndex] = useState<number>();
	//searchParams?.organizationVerticalCode ? 1 : 0

	const [formData, setFormData] = useState<any>({});
	const [serverErrors] = useState<any>([]);

	useEffect(() => {
		if (searchParams?.code) setActiveStepIndex(1);
		else if (searchParams?.connection) setActiveStepIndex(1);
		else setActiveStepIndex(0);
	}, [searchParams?.code, searchParams?.connection]);

	function _handleSubmit(values: any) {
		let newVal = {
			...searchParams,
			...values,
			_id: searchParams?.organizationGroupCode,
		};
		verifyCode(values?.code, searchParams?.coacheeId, (res: any) => {
			if (res)
				window.location.href =
					'/register/coachee?' +
					new URLSearchParams({
						email: newVal?.email,
						firstName: newVal?.firstName,
						lastName: newVal?.lastName,
						organization: searchParams?.organization,
						organizationVertical: searchParams?.organizationVertical,
						organizationVerticalCode: newVal?.organizationVerticalCode,
						code: newVal?.organizationVerticalCode,
					});
		});
	}

	return (
		<FormContext.Provider
			value={{
				activeStepIndex,
				setActiveStepIndex,
				formData,
				setFormData,
				serverErrors,
			}}
		>
			{/* <Text
				fontSize="h4"
				lineHeight="h4"
				color="secondary.50"
				fontFamily="Archivo-Bold"
				mt="0.7rem"
				mb="5.6rem"
			>
				My Coaching Place
			</Text> */}

			<Flex justifyContent={'start'} mt="0.7rem" mb="5.6rem">
				<Icon name="logo" height="7rem" type={'text'} />
			</Flex>

			<Box>
				{activeStepIndex === 1 ? (
					<ClientWelcome onSubmit={_handleSubmit} />
				) : (
					<ClientGetCode onSubmit={_handleSubmit} />
				)}
			</Box>
		</FormContext.Provider>
	);
}

export default ClientRegisterPage;
