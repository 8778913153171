import { useState } from 'react';
import './TextEllipsis.css'; // Import your CSS file with ellipsis styles

export const TextEllipsis = ({ text, linesToShow, buttonText }: any) => {
	const [showFullText, setShowFullText] = useState(false);

	const toggleTextVisibility = () => {
		setShowFullText((prev) => !prev);
	};



	return (
		// <div className="text-ellipsis-container">
		// 	<div className={`text-content ${showFullText ? 'show-full-text' : ''}`}>{text}</div>
		// 	{text.length > linesToShow * 40 && (
		// 		<button className="show-more-button" onClick={toggleTextVisibility}>
		// 			{showFullText ? 'Show Less' : 'Show More'}
		// 		</button>
		// 	)}
		// </div>
		
		<div style={{
			maxWidth: '100%',
			display: '-webkit-box',
			WebkitBoxOrient: 'vertical',
			WebkitLineClamp: `${showFullText?'unset':linesToShow}`,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			wordBreak: "break-all"
		}}>
			{text}
			{
				text?.length >= 242 && (
			<button className="show-more-button" onClick={toggleTextVisibility}>
				{showFullText ? `${buttonText} Less` : `... ${buttonText} More`}
			</button>
			)}
		</div>
	);
};

//export default TextEllipsis;
