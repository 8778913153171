import { FC, Suspense } from 'react';
import { Box, Grid, Loader, Image } from 'atoms';

import { UserType } from 'typings/enums/users';

export type AuthLayoutProps = {
	children?: React.ReactNode;
	userType?: UserType;
};

export const AuthLayout: FC<AuthLayoutProps> = ({
	children,
	userType,
	...props
}: AuthLayoutProps) => {
	return (
		<Box
		height="100vh"
		overflowY="scroll"
		backgroundColor="#F1F9FA"
		display="flex"
		flexDirection={{xs:"column-reverse", md:"row"}}
			// gridAutoFlow={{ md: 'column' }}
			// gridTemplateColumns={{ xs: '1fr', md: '50% 50%' }}
		>
			<Box
				width={{ xs: "100%", md: "50%" }}
				py="5rem"
				backgroundColor="white.0"
				boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)">
				<Box height="100%">
					<Box
						mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
						minWidth={{ sm: '32rem', lg: '44rem' }}
						maxWidth="90%"
					>
						<Suspense fallback={<Loader />}>{children}</Suspense>
					</Box>
				</Box>
			</Box>
			<Box
				backgroundColor="#F1F9FA"
				minHeight={{xs:"unset", md:"100vh"}}
				p={{ xs:"3rem", md:"2rem"}}
				display="flex"
				flexGrow="1"
				justifyContent="center"
				alignItems="center"
				width={{xs:"100%", md:"50%"}}
			>
				{
						location?.pathname?.includes('/complete-profile') && (
								<Image
								src={require('assets/images/login/s4.png')}
								alt="A man seeing his goal"
									/>
						)
				}
				{
						(location?.pathname?.includes('/code-verify')) && (
								<Image
								src={require('assets/images/login/s5.png')}
								alt="A man seeing his goal"
									/>
						)
					}
			</Box>
		</Box>
	);
};
