export const PAGE_SIZE = 10;

export const PROFILE_PLACEHOLDER = require(`assets/images/profile.png`);
export const BRAND_PLACEHOLDER = `${process.env.REACT_APP_S3_BASE_URL}/defaults/brand.png`;
export const ASSET_ENDPOINT = `${process.env.REACT_APP_S3_BASE_URL}/`;
export const FRESHDESK_CONTACT_URL = `${process.env.REACT_APP_FRESHDESK_URL}/`;
export const FRESHDESK_CHAT_TOKEN = `${process.env.REACT_APP_FRESHDESK_CHAT_TOKEN}`;
export const OUTSETA_ENDPOINT = `${process.env.REACT_APP_OUTSETA_API_URL}/`;

// Regex's
export const FIRSTNAME_REGEX = /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\<>?:;|=.,0123456789]{1,25}$/;
export const LASTNAME_REGEX = /^[a-zA-Z]+(\s[a-zA-Z]+)?$/;
export const PHONE_NUMBER_REGEX =
	/^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/gm;
export const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const URL_REGEX =
	/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const ALPHANUMERIC_SPACE = /^[a-zA-Z0-9 ]*$/;
export const ALPHANUMERIC_HYPHEN = /^[a-zA-Z0-9-_]+$/;

export const YesOrNoOptions = [
	{
		label: 'Yes',
		value: '1',
	},
	{
		label: 'No',
		value: '2',
	},
];

export const CoachingOptions = [
	{
		label: 'Send Requests to Coachees',
		value: 0,
	},
	{
		label: 'Send Requests to Coachees  and Receive Requests from Coachees',
		value: 1,
	},
	// {
	// 	label: `Send Requests to Coaches and Receive Requests from Coaches`,
	// 	value: '3',
	// },
];

export const SupervisionOptions = [
	{
		label: 'I would be interested in matching with a supervisor for 1 - 1 supervision',
		value: 0,
	},
	{
		label: 'I would be interested in matching with a supervisor for group supervision',
		value: 1,
	},
	{
		label: `I'm not interested in matching with a supervisor`,
		value: 2,
	},
];

export const MentoringOptions = [
	{
		label: 'Interested in offering mentoring and receiving mentoring',
		value: 0,
	},
	{
		label: 'interested in offering mentoring but not in receiving mentoring',
		value: 1,
	},
	{
		label: `Not interested in offering mentoring but  Interested in receiving mentoring`,
		value: 2,
	},
	{
		label: `Not interested in offering mentoring nor receiving mentoring`,
		value: 3,
	},
];

export const MentoringCoachingOptions = [
	{
		label: 'I would be interested in matching with a mentor coach',
		value: 1,
	},
	{
		label: 'I am not interested in matching with a mentor coach',
		value: 0,
	},
];

export const sessionPackages = [
	{
		label: '6 sessions of 90 min',
		value: '6 of 90 mins',
	},
	{
		label: '6 sessions of 60 min ',
		value: '6 of 60 mins',
	},
	{
		label: '3 sessions of 90 min',
		value: '3 of 90 mins',
	},
	{
		label: '3 sessions of 60 min ',
		value: '3 of 60 mins',
	},
];

export const CurrentCredentials = [
	{
		label: 'EMCC Practitioner',
		value: '0',
	},
	{
		label: 'EMCC Senior Practitioner',
		value: '1',
	},
	{
		label: 'EMCC Master Practitioner',
		value: '2',
	},
	{
		label: 'EMCC Coach/Mentor Supervisor',
		value: '3',
	},
	{
		label: 'ICF - Associate Certified Coach (ACC)',
		value: '4',
	},
	{
		label: 'ICF - Professional Certified Coach (PCC)',
		value: '5',
	},
	{
		label: 'ICF - Master Certified Coach (MCC)',
		value: '6',
	},
	{
		label: 'ICF-CCS',
		value: '7',
	},
	{
		label: 'AC - Accredited Coach (AC)',
		value: '8',
	},
	{
		label: 'AC - Accredited Professional Coach (APC)',
		value: '9',
	},
	{
		label: 'AC - Accredited Senior Coach (ASC)',
		value: '10',
	},
	{
		label: 'AC - Accredited Master Coach (AMC)',
		value: '11',
	},
	{
		label: 'AC - Accredited Coaching Supervisor (ACS)',
		value: '12',
	},
];

export const CoachingCredentials = [
	{
		label: 'EMCC Practitioner',
		value: '0',
	},
	{
		label: 'EMCC Senior Practitioner',
		value: '1',
	},
	{
		label: 'EMCC Master Practitioner',
		value: '2',
	},
	{
		label: 'EMCC Coach/Mentor Supervisor',
		value: '3',
	},
	{
		label: 'ICF - Associate Certified Coach (ACC)',
		value: '4',
	},
	{
		label: 'ICF - Professional Certified Coach (PCC)',
		value: '5',
	},
	{
		label: 'ICF - Master Certified Coach (MCC)',
		value: '6',
	},
	{
		label: 'ICF-CCS',
		value: '7',
	},
	{
		label: 'AC - Accredited Coach (AC)',
		value: '8',
	},
	{
		label: 'AC - Accredited Professional Coach (APC)',
		value: '9',
	},
	{
		label: 'AC - Accredited Senior Coach (ASC)',
		value: '10',
	},
	{
		label: 'AC - Accredited Master Coach (AMC)',
		value: '11',
	},
	{
		label: 'AC - Accredited Coaching Supervisor (ACS)',
		value: '12',
	},
];

export const CoachingBodies = [
	{
		label: 'EMCC',
		value: '0',
	},
	{
		label: 'ICF',
		value: '1',
	},
	{
		label: 'AC',
		value: '2',
	},
	{
		label: 'Other',
		value: '3',
	},
];

export const AssessmentTools = [
	{
		label: '16 Personalities',
		value: '0',
	},
	{
		label: '360-Degree Feedback',
		value: '1',
	},
	{
		label: 'DiSC Assessment',
		value: '2',
	},
	{
		label: 'Emotional Intelligence (EI) Assessment',
		value: '3',
	},
	{
		label: 'Gallup Q12 Employee Engagement Survey',
		value: '4',
	},
	{
		label: 'Hogan Personality Inventory (HPI)',
		value: '5',
	},
	{
		label: 'Myers-Briggs Type Indicator (MBTI)',
		value: '6',
	},
	{
		label: 'StrengthsFinder',
		value: '7',
	},
	{
		label: 'The Big 5 Personality Traits',
		value: '8',
	},
	{
		label: 'Thomas-Kilmann Conflict Mode Instrument',
		value: '9',
	},
	{
		label: 'VIA Character Strengths',
		value: '10',
	},
	{
		label: 'Other',
		value: '11',
	},
];

export const OtherAssessmentTools = [
	{
		label: '16 Personality Factor (16PF) Questionnaire',
		value: '0',
	},
	{
		label: 'Cognitive Ability Tests (e.g., Wonderlic Personnel Test)',
		value: '1',
	},
	{
		label: 'DiSC Assessment',
		value: '2',
	},
	{
		label: 'Emotional Intelligence Assessment (EQ-i)',
		value: '3',
	},
	{
		label: 'ECR',
		value: '4',
	},
	{
		label: 'General Aptitude Test Battery (GATB)',
		value: '5',
	},
	{
		label: 'Hogan Personality Inventory (HPI)',
		value: '6',
	},
	{
		label: 'Insights Discovery',
		value: '7',
	},
	{
		label: 'Kolb Learning Style Inventory',
		value: '8',
	},
	{
		label: 'Learning Styles Assessment',
		value: '9',
	},
	{
		label: 'Lumina Spark',
		value: '10',
	},
	{
		label: 'Motivational Maps',
		value: '11',
	},
	{
		label: 'Myers-Briggs Type Indicator (MBTI)',
		value: '12',
	},
	{
		label: 'Occupational Personality Questionnaire (OPQ)',
		value: '13',
	},
	{
		label: 'Situational Judgment Tests (SJT)',
		value: '14',
	},
	{
		label: 'StrengthsFinder',
		value: '15',
	},
	{
		label: 'Thomas-Kilmann Conflict Mode Instrument (TKI)',
		value: '16',
	},
	{
		label: 'VoicePrint',
		value: '17',
	},
	{
		label: 'Wonderlic Personnel Test',
		value: '18',
	},
	{
		label: 'Other',
		value: '19',
	},
];

export const CareerExperienceOptions = [
	{ label: '0-5', value: '5' },
	{ label: '6-10', value: '10' },
	{ label: '11-15', value: '15' },
	{ label: '16-20', value: '20' },
	{ label: '21-25', value: '25' },
	{ label: '26-30', value: '30' },
	{ label: '30+', value: '31' },
];

export const ClientTypes = [
	{
		label: 'Corporate',
		value: '1',
	},
	{
		label: 'Charity',
		value: '2',
	},
	{
		label: 'Good Cause',
		value: '3',
	},
	{
		label: 'Individual',
		value: '4',
	},
];
